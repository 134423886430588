import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import Button from '@/components/fat-basicComponents/button';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { initialValuesFromTable } from '@/components/table/constants';
import FilterPage from '@/components/table/fat-filterPage';
import TablePagination from '@/components/table/tablePagination';
import { IItems, ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import AutoPlan from '../fat-pacingTab/fat-modals/autoPlan';
import { AUTO_PLAN_STATUS_ENUM, ICommitmentPlan, PaginatedCommitmentPlans } from '../fat-pacingTab/types';
import { COMMITMENT_PLANS_QUERY, updateCommitmentPlanMutation } from '../queries';
import { mobileTableSort } from './constatnts';
import DeletePlan from './fat-modals/deletePlan';
import Table from './table/table';

export const AllCommitmentPlans = () => {
  const { isMobile } = useResponsive();
  const params = useParams();
  const goBack = useGoBack();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const key = searchParams.get('key') || 'START_DATE';
  const asc = searchParams.get('asc') === 'true' || false;
  const backToTitle = searchParams.get('backToTitle') || '';
  const entityName = searchParams.get('entityName') || '';
  const q = searchParams.get('q') || '';

  const [tableData, setTableData] = useState<ICommitmentPlan[]>([]);
  const [currentRow, setCurrentRow] = useState<ICommitmentPlan | null>(null);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'auto-plan'
  });
  const [mobileSort, setMobileSort] = useState(mobileTableSort[0].title);
  const sortItem = mobileTableSort.find((item) => item.title === mobileSort);
  const mobileSortList = mobileTableSort.map((item) => item.title);

  const [updateCommitmentPlan, { loading: updateCommitmentPlanLoading }] = useMutation(updateCommitmentPlanMutation, {
    onError: (error) => {
      console.error(error);
    }
  });

  const {
    data: commitmentPlansData,
    refetch: commitmentPlansRefetch,
    networkStatus: commitmentPlansNetworkStatus
  } = useQuery<{ commitmentPlans: PaginatedCommitmentPlans }>(COMMITMENT_PLANS_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.entityId,
    variables: {
      input: {
        legalEntityId: params?.entityId,
        search: q.toLowerCase().trim(),
        sortBy: key,
        sortDirection: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        limit: Number(limit),
        offset: Number(offset)
      }
    },
    onCompleted: (data) => {
      setTableData(data.commitmentPlans.data);
    }
  });

  useEffect(() => {
    if (!isMobile) return;
    commitmentPlansRefetch({
      input: {
        legalEntityId: params?.entityId,
        search: q.toLowerCase().trim(),
        sortBy: sortItem?.key,
        sortDirection: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        limit: Number(limit),
        offset: Number(offset)
      }
    });
  }, [mobileSort]);

  const openModifyCommitmentPlan = () => {
    navigate(`/investors/${params.investorId}/${params.entityId}/autoPlan?backToTitle=All Commitment Plans`);
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.delete('limit');
        prev.delete('offset');
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const openModalWindow = (type: string) => {
    setModalWindow({ type, isOpen: true });
  };

  const closeModalWindow = () => {
    setCurrentRow(null);
    setModalWindow({ ...modalWindow, isOpen: false });
  };

  const deleteCommitmentPlan = (row: ICommitmentPlan) => {
    setCurrentRow(row);
    openModalWindow('delete-plan');
  };

  const makeActivePlan = (row: ICommitmentPlan) => {
    setCurrentRow(row);
    updateCommitmentPlan({
      variables: {
        input: {
          id: row.id,
          active: true
        }
      },
      onCompleted: () => {
        commitmentPlansRefetch();
      }
    });
  };

  const dropdownActions = (row: ICommitmentPlan): IItems[] => {
    const isCommitmentPlanComplete =
      row.autoPlanStatus !== AUTO_PLAN_STATUS_ENUM.IN_PROGRESS &&
      row.autoPlanStatus !== AUTO_PLAN_STATUS_ENUM.WAITING_FOR_REVIEW &&
      row.autoPlanStatus !== AUTO_PLAN_STATUS_ENUM.FAILED;

    return [
      ...(isCommitmentPlanComplete
        ? [
            {
              name: 'Edit Plan',
              onClick: () =>
                navigate(
                  `/investors/${params.investorId}/${params.entityId}/editCommitmentPlan?commitmentPlanId=${row.id}&backToTitle=All Commitment Plans`
                )
            }
          ]
        : [
            {
              name: 'Edit Plan',
              onClick: () =>
                navigate(`/investors/${params.investorId}/${params.entityId}?backToTitle=All Commitment Plans&tab=Pacing&commitmentPlanId=${row.id}`)
            }
          ]),
      {
        name: 'View Forecast',
        onClick: () =>
          navigate(`/investors/${params.investorId}/${params.entityId}?backToTitle=All Commitment Plans&tab=Pacing&commitmentPlanId=${row.id}`)
      },
      ...(isCommitmentPlanComplete
        ? [
            {
              name: 'Make Active Plan',
              onClick: () => makeActivePlan(row)
            }
          ]
        : []),
      ...(isCommitmentPlanComplete
        ? [
            {
              name: 'Duplicate Plan',
              onClick: () =>
                navigate(
                  `/investors/${params.investorId}/${params.entityId}/editCommitmentPlan?commitmentPlanId=${row.id}&duplicate=true&backToTitle=All Commitment Plans`
                )
            }
          ]
        : []),
      {
        name: 'Delete Plan',
        onClick: () => deleteCommitmentPlan(row)
      }
    ];
  };

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'auto-plan' && (
        <AutoPlan isOpen={modalWindow.isOpen} onClose={closeModalWindow} entityName={entityName} openAutoPlanDialogFrom="allCommitmentPlans" />
      )}
      {modalWindow.isOpen && modalWindow.type === 'delete-plan' && (
        <DeletePlan isOpen={modalWindow.isOpen} onClose={closeModalWindow} currentRow={currentRow} refetch={commitmentPlansRefetch} />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
        <PageTitle title="All Commitment Plans" />
        <PaddingWrap>
          <AddButtonWrapper>
            <Button size="md" onClick={openModifyCommitmentPlan} icon={<AddButtonIcon />}>
              Add New Commitment Plan
            </Button>
          </AddButtonWrapper>
          <CustomFilterPage
            isMobile={isMobile}
            search={{
              value: q,
              onChange: onChangeSearch,
              placeholder: 'Search'
            }}
            refetch={commitmentPlansRefetch}
            resultsValue={commitmentPlansData?.commitmentPlans.total ?? 0}
            mobileSort={{
              value: mobileSort,
              onChange: setMobileSort,
              selects: mobileSortList
            }}
          />
          <Table
            tableData={tableData}
            loading={commitmentPlansNetworkStatus === NetworkStatus.loading || commitmentPlansNetworkStatus === NetworkStatus.setVariables}
            sort={{ key, asc }}
            updateLoading={updateCommitmentPlanLoading || commitmentPlansNetworkStatus === NetworkStatus.refetch}
            currentRow={currentRow}
            setSort={onChangeSort}
            dropdownActions={dropdownActions}
          />
          <PaginationWrap>
            <TablePagination
              savePagination={onChangePaginationData}
              paginationValues={{
                limit: Number(limit),
                offset: Number(offset),
                total: commitmentPlansData?.commitmentPlans.total ?? 0
              }}
              refetch={() => null as any}
            />
          </PaginationWrap>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const AddButtonWrapper = styled.div`
  margin-top: 50px;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean }>`
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
