import { getUTCDate } from '@/components/allocations-page/utils';
import ModalLoader from '@/components/fat-basicComponents/modal/modalLoader';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { updateLegalEntityMutation } from '@/components/fat-investors-page/fat-addInvestors/queries';
import { ILegalEntity } from '@/components/fat-investors-page/fat-addInvestors/types';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { autoPlanMutation } from '../queries';
import { AllocationTargets } from './steps/allocationTargets';
import { AutoPlan } from './steps/autoPlan';
import { ForecastOptions } from './steps/forecastOptions';
import { PlanDetails } from './steps/planDetails';

const autoPlanWizardStepsInitial = [
  { name: 'Plan Details', status: 'IN_PROGRESS' },
  { name: 'Allocation Targets', status: 'NOT_STARTED' },
  { name: 'Forecast Options', status: 'NOT_STARTED' },
  { name: 'Auto Plan', status: 'NOT_STARTED' }
];

export interface ICreateAutoPlanForm {
  name: string;
  startDate: 'Today' | 'Next Quarter';
  yearsToAchieveAllocationTarget: string;
  biteSize: string;
  growthRate: string;
  totalEntityValue: string;
  generateAllocations: boolean;
}

const defaultFormValues: ICreateAutoPlanForm = {
  name: '',
  startDate: 'Today',
  yearsToAchieveAllocationTarget: '5',
  biteSize: '',
  growthRate: '',
  totalEntityValue: '',
  generateAllocations: true
};

const GET_LEGAL_ENTITY_MIN_QUERY = gql`
  query LegalEntity($id: String!) {
    legalEntity(id: $id) {
      entityName
      totalEntityValue
    }
  }
`;

const getDefaultPlanStart = (): 'Today' | 'Next Quarter' => {
  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();

  const quarterStartMonth = Math.floor(month / 3) * 3;
  const quarterEndMonth = quarterStartMonth + 2;

  const quarterStartDate = new Date(year, quarterStartMonth, 1);
  const quarterEndDate = new Date(year, quarterEndMonth + 1, 0);

  const quarterMidDate = new Date(quarterStartDate.getTime() + (quarterEndDate.getTime() - quarterStartDate.getTime()) / 2);

  if (today <= quarterMidDate) {
    return 'Today';
  }
  return 'Next Quarter';
};

const startDateValues = {
  Today: 'TODAY',
  'Next Quarter': 'NEXT_QUARTER'
};

const AutoPlanWizardPage = () => {
  const { handleSubmit, control, reset, trigger } = useForm<ICreateAutoPlanForm>({ defaultValues: defaultFormValues });
  const goBack = useGoBack();
  const params = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const backToTitle = searchParams.get('backToTitle') || '';

  const [autoPlanWizardSteps, setAutoPlanWizardSteps] = useState(autoPlanWizardStepsInitial);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createAutoPlan, { loading: createAutoPlanLoading }] = useMutation(autoPlanMutation, {
    onError: (error) => {
      window.scrollTo(0, 0);
      console.error(error);
    }
  });

  const [updateEntity, { loading: updateEntityLoading }] = useMutation(updateLegalEntityMutation, {
    onError: (error) => {
      window.scrollTo(0, 0);
      console.error(error);
    }
  });

  const { data: legalEntityData, loading: legalEntityLoading } = useQuery<{ legalEntity: ILegalEntity }>(GET_LEGAL_ENTITY_MIN_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.entityId,
    variables: {
      id: params?.entityId
    }
  });

  useEffect(() => {
    if (legalEntityLoading) return;

    reset({
      ...defaultFormValues,
      name: `Plan for ${legalEntityData?.legalEntity?.entityName ?? ''} ${getUTCDate(new Date()).toUpperCase()}`,
      startDate: getDefaultPlanStart(),
      totalEntityValue: legalEntityData?.legalEntity?.totalEntityValue?.toLocaleString('en-US') ?? ''
    });
  }, [legalEntityData, legalEntityLoading]);

  const nextStep = () => {
    const currentStepIndex = autoPlanWizardSteps.findIndex((step) => step.status === 'IN_PROGRESS');
    if (currentStepIndex === autoPlanWizardSteps.length - 1) return;

    const updatedAutoPlanWizardSteps = autoPlanWizardSteps.map((step, index) => {
      if (currentStepIndex === index) {
        return { ...step, status: 'COMPLETED' };
      }
      if (currentStepIndex + 1 === index) {
        return { ...step, status: 'IN_PROGRESS' };
      }

      return step;
    });

    setAutoPlanWizardSteps(updatedAutoPlanWizardSteps);
  };

  const startOver = () => {
    setAutoPlanWizardSteps(autoPlanWizardStepsInitial);
  };

  const onSave = async (data: ICreateAutoPlanForm) => {
    setIsSubmitting(true);
    const { name, growthRate, yearsToAchieveAllocationTarget, startDate, generateAllocations, totalEntityValue } = data;

    try {
      await updateEntity({
        variables: {
          data: {
            id: params.entityId,
            totalEntityValue: Number(totalEntityValue.replace(/\D/g, ''))
          }
        }
      });

      const { data: createAutoPlanData } = await createAutoPlan({
        variables: {
          input: {
            legalEntityId: params.entityId,
            name: name.trim(),
            startDate: startDateValues[startDate],
            growthRate: parseFloat(growthRate),
            yearsToAchieveAllocationTarget: parseInt(yearsToAchieveAllocationTarget),
            generateAllocations: generateAllocations
          }
        }
      });

      if (generateAllocations && createAutoPlanData?.autoPlan?.id) {
        navigate(`/investors/${params.investorId}/${params.entityId}?tab=Pacing&commitmentPlanId=${createAutoPlanData.autoPlan.id}`, {
          replace: true
        });
        return;
      }

      navigate(`/investors/${params.investorId}/${params.entityId}/editCommitmentPlan?backToTitle=Investor Entity`, {
        replace: true,
        state: { autoPlanData: JSON.stringify(createAutoPlanData.autoPlan) }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (legalEntityLoading) {
    return <LoaderOnWholeScreen />;
  }

  return (
    <>
      {(isSubmitting || createAutoPlanLoading || updateEntityLoading) && (
        <ModalWrappedContent
          isOpen={true}
          onClose={() => {}}
          title="Creating Auto Plan"
          showCloseIcon={false}
          showCancelButton={false}
          isUseOutsideClick={false}
        >
          <ModalLoader />
        </ModalWrappedContent>
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
        <PageTitle title="Auto Plan" />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          {autoPlanWizardSteps[0].status === 'IN_PROGRESS' && (
            <PlanDetails autoPlanWizardSteps={autoPlanWizardSteps} control={control} trigger={trigger} startOver={startOver} nextStep={nextStep} />
          )}
          {autoPlanWizardSteps[1].status === 'IN_PROGRESS' && (
            <AllocationTargets
              autoPlanWizardSteps={autoPlanWizardSteps}
              control={control}
              trigger={trigger}
              startOver={startOver}
              nextStep={nextStep}
            />
          )}
          {autoPlanWizardSteps[2].status === 'IN_PROGRESS' && (
            <ForecastOptions
              autoPlanWizardSteps={autoPlanWizardSteps}
              control={control}
              trigger={trigger}
              startOver={startOver}
              nextStep={nextStep}
            />
          )}
          {autoPlanWizardSteps[3].status === 'IN_PROGRESS' && (
            <AutoPlan
              autoPlanWizardSteps={autoPlanWizardSteps}
              control={control}
              isSubmitting={isSubmitting}
              startOver={startOver}
              nextStep={handleSubmit(onSave)}
            />
          )}
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

export default AutoPlanWizardPage;
