import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import FilterSelect from '@/components/basicComponents/select';
import Label from '@/components/fat-basicComponents/label';
import FooterModal from '@/components/fat-settings-page/importData/footer';
import { ProgressBar } from '@/components/fat-settings-page/importData/progressBar';
import { ImportWrapper } from '@/components/fat-settings-page/importData/styles';
import { AnimatePresence, motion } from 'framer-motion';
import { Control, Controller, useWatch } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { ICreateAutoPlanForm } from '..';

interface AutoPlanProps {
  autoPlanWizardSteps: { name: string; status: string }[];
  control: Control<ICreateAutoPlanForm, any>;
  isSubmitting: boolean;
  startOver: () => void;
  nextStep: () => void;
}

const automaticallyGenerateOptions = ['Yes, create my plan and I’ll make any necessary edits', 'No, I will enter all commitment amounts manually'];

export const AutoPlan = ({ autoPlanWizardSteps, control, isSubmitting, startOver, nextStep }: AutoPlanProps) => {
  const theme = useTheme();
  const generateAllocationsValue = useWatch({
    control,
    name: 'generateAllocations'
  });

  return (
    <>
      <StepWrapper>
        <ProgressBar importSteps={autoPlanWizardSteps} />
        <StepDetailsWrapper>
          <StepTitle>Auto Plan</StepTitle>
          <StepDescription>
            For investors that are new to private capital, you may prioritize an aggressive commitment plan that ramps up to the target allocation
            quickly. Alternatively, a more relaxed pace will prioritize vintage diversification. With Auto Plan you can choose an appropriate balance.
          </StepDescription>
        </StepDetailsWrapper>
        <StepBodyWrapper>
          <FieldWrapper>
            <div>
              <Label size="md">Automatically Generate Commitment Amounts</Label>
              <Controller
                name="generateAllocations"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    {automaticallyGenerateOptions.map((option, index) => (
                      <RadioButton
                        key={option + index}
                        name="useExistingLegalEntity"
                        radioButtonText={option}
                        selectedOption={value ? automaticallyGenerateOptions[0] : automaticallyGenerateOptions[1]}
                        handleRadioChange={(event) => onChange(event.target.value === automaticallyGenerateOptions[0])}
                      />
                    ))}
                  </>
                )}
              />
            </div>
            <div>
              <AnimatePresence mode="wait">
                {generateAllocationsValue ? (
                  <motion.div
                    key="yearsToAchieveAllocationTargetSelect"
                    initial={{
                      opacity: 0
                    }}
                    animate={{
                      opacity: 1,
                      transition: {
                        duration: 0.2
                      }
                    }}
                    exit={{
                      opacity: 0,
                      transition: {
                        duration: 0.2
                      }
                    }}
                  >
                    <Label required>Years to achieve allocation target</Label>
                    <Controller
                      name="yearsToAchieveAllocationTarget"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <PlanStartSelect data={['5', '6', '7', '8', '9', '10']} selected={value} setSelected={onChange} width="100%" />
                      )}
                    />
                  </motion.div>
                ) : (
                  <motion.div
                    key="yearsToAchieveAllocationTargetBanner"
                    initial={{
                      opacity: 0
                    }}
                    animate={{
                      opacity: 1,
                      transition: {
                        duration: 0.2
                      }
                    }}
                    exit={{
                      opacity: 0,
                      transition: {
                        duration: 0.2
                      }
                    }}
                  >
                    <Banner
                      title=""
                      description="You will start with a completely blank plan.  You will need to provide all future commitment values."
                      icon={<RejectedBannerIcon fill={theme.header.base} />}
                      bgColor={theme.layer[1]}
                      color={theme.font.base}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </FieldWrapper>
        </StepBodyWrapper>
      </StepWrapper>
      <FooterModal startOver={startOver} confirmButton={{ name: 'Save and Complete', onClick: nextStep, disabled: isSubmitting }} />
    </>
  );
};

const StepWrapper = styled(ImportWrapper)`
  margin-bottom: 150px;
`;

const StepDetailsWrapper = styled.div``;

const StepTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 22.4px;
  text-align: center;
  margin-top: 10px;
  color: ${({ theme }) => theme.font.base};
`;

const StepDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  padding: 10px;
  color: ${({ theme }) => theme.font.weak};
`;

const StepBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const PlanStartSelect = styled(FilterSelect)`
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px 12px;
  border-radius: 5px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 19px;
    line-height: 26.6px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;
