import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import Input from '@/components/fat-basicComponents/input';
import FooterModal from '@/components/fat-settings-page/importData/footer';
import { ProgressBar } from '@/components/fat-settings-page/importData/progressBar';
import { ImportWrapper } from '@/components/fat-settings-page/importData/styles';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { ICreateAutoPlanForm } from '../..';
import { DrawdownTargetsDto } from '../../../fat-pacingTab/types';
import { ChartWithTable } from './chartWithTable';

interface AllocationTargetsProps {
  autoPlanWizardSteps: { name: string; status: string }[];
  control: Control<ICreateAutoPlanForm, any>;
  drawdownTargetDialogData?: DrawdownTargetsDto | null;
  trigger: UseFormTrigger<ICreateAutoPlanForm>;
  startOver: () => void;
  nextStep: () => void;
}

const MOCKED_DATA = {
  actuals: {
    drawdown: [
      {
        name: 'Distressed',
        portfolioPercentage: 0,
        privateCapitalPercentage: 0,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Mezz',
        portfolioPercentage: 0,
        privateCapitalPercentage: 0,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Senior Loans',
        portfolioPercentage: 0,
        privateCapitalPercentage: 0,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Buyout',
        portfolioPercentage: 0,
        privateCapitalPercentage: 0,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Secondary',
        portfolioPercentage: 0,
        privateCapitalPercentage: 0,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Venture/Growth Equity',
        portfolioPercentage: 0,
        privateCapitalPercentage: 0,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Energy',
        portfolioPercentage: 0,
        privateCapitalPercentage: 0,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Infrastructure',
        portfolioPercentage: 0,
        privateCapitalPercentage: 0,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Real Estate',
        portfolioPercentage: 0,
        privateCapitalPercentage: 0,
        __typename: 'DrawdownTargetPercentagesDto'
      }
    ],
    evergreen: [
      {
        name: 'Diversifying Strategies',
        portfolioPercentage: 0,
        privateCapitalPercentage: 0,
        __typename: 'DrawdownTargetPercentagesDto'
      }
    ],
    __typename: 'DrawdownTargetBreakdownDto'
  },
  targets: {
    drawdown: [
      {
        name: 'Distressed',
        portfolioPercentage: 6.25,
        privateCapitalPercentage: 6.25,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Mezz',
        portfolioPercentage: 6.25,
        privateCapitalPercentage: 6.25,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Senior Loans',
        portfolioPercentage: 12.5,
        privateCapitalPercentage: 12.5,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Buyout',
        portfolioPercentage: 15,
        privateCapitalPercentage: 15,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Secondary',
        portfolioPercentage: 2.5,
        privateCapitalPercentage: 2.5,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Venture/Growth Equity',
        portfolioPercentage: 7.5,
        privateCapitalPercentage: 7.5,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Energy',
        portfolioPercentage: 10,
        privateCapitalPercentage: 10,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Infrastructure',
        portfolioPercentage: 5,
        privateCapitalPercentage: 5,
        __typename: 'DrawdownTargetPercentagesDto'
      },
      {
        name: 'Real Estate',
        portfolioPercentage: 10,
        privateCapitalPercentage: 10,
        __typename: 'DrawdownTargetPercentagesDto'
      }
    ],
    evergreen: [
      {
        name: 'Diversifying Strategies',
        portfolioPercentage: 25,
        privateCapitalPercentage: 25,
        __typename: 'DrawdownTargetPercentagesDto'
      }
    ],
    __typename: 'DrawdownTargetBreakdownDto'
  },
  __typename: 'DrawdownTargetsDto'
};

export const AllocationTargets = ({ autoPlanWizardSteps, control, trigger, startOver, nextStep }: AllocationTargetsProps) => {
  const theme = useTheme();

  const moveToTheNextStep = async () => {
    const isTotalEntityValueValid = await trigger('totalEntityValue');
    if (!isTotalEntityValueValid) return;

    nextStep();
  };

  return (
    <>
      <StepWrapper>
        <ProgressBar importSteps={autoPlanWizardSteps} />
        <StepDetailsWrapper>
          <StepTitle>Allocation Targets</StepTitle>
          <StepDescription>
            A commitment plan is designed to achieve the investor’s Net Asset Value in each asset class according to the allocation targets shown
            here. Because drawdown funds involve capital calls and distributions ar irregular intervals, it can be difficult to maintain a desired
            allocation target over time. Auto Plan uses a forecasting algorithm to automatically generate a commitment plan that achieves these
            targets.
          </StepDescription>
        </StepDetailsWrapper>
        <StepBodyWrapper>
          <FieldWrapper>
            <Controller
              name="totalEntityValue"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Current Portfolio Value is required'
                }
              }}
              render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <Input
                  label="Current Portfolio Value"
                  value={value}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/[^0-9.]/g, '');
                    const formattedValue = inputValue ? Number(inputValue).toLocaleString('en-US') : '';
                    onChange(formattedValue);
                  }}
                  errorText={error?.message}
                  withDollarSign
                  isRequiredField
                />
              )}
            />
            <Banner
              title=""
              description="Set the investor entity’s current portfolio value.  This figure influences the size of commitments specified in your plan."
              icon={<RejectedBannerIcon fill={theme.header.base} />}
              bgColor={theme.layer[1]}
              color={theme.font.base}
            />
          </FieldWrapper>
          <ChartWithTable drawdownTargetDialogData={MOCKED_DATA} />
        </StepBodyWrapper>
      </StepWrapper>
      <FooterModal startOver={startOver} confirmButton={{ name: 'Continue', onClick: moveToTheNextStep, disabled: false }} />
    </>
  );
};

const StepWrapper = styled(ImportWrapper)`
  margin-bottom: 150px;
`;

const StepDetailsWrapper = styled.div``;

const StepTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 22.4px;
  text-align: center;
  margin-top: 10px;
  color: ${({ theme }) => theme.font.base};
`;

const StepDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  padding: 10px;
  color: ${({ theme }) => theme.font.weak};
`;

const StepBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;
