import { format } from 'date-fns';
import styled, { useTheme } from 'styled-components';

import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import Button from '@/components/fat-basicComponents/button';
import { formatCash } from '@/utils/formatCash';
import { headingsPlugin, linkDialogPlugin, linkPlugin, listsPlugin, MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { DefaultTheme } from 'styled-components/dist/types';
import { useResponsive } from '../../../hooks/use-responsive';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { extractAndFormatDate } from './edit-pages/dateContent';
import { extractAmount } from './edit-pages/dollarContent';
import { extractNumbersFromString } from './edit-pages/numberContent';
import { extractPercentage } from './edit-pages/percentageContent';
import ShowText from './showText';
import { ISection, ISectionFields, RecordStatusEnum, TierStatusEnum } from './types';
import { decimalToPercentage, isImageExtension } from './utils';

interface TierSectionProps {
  sectionData: ISection;
  prevTierStatus: TierStatusEnum | null;
  openEditPage: (type: string, row: ISectionFields) => void;
  recordStatus: RecordStatusEnum | null;
  openModalWindow: (type: string, row: ISectionFields) => void;
}

const fieldValue = (
  row: ISectionFields,
  index: number,
  theme: DefaultTheme,
  longFormContentEditorRefs: MutableRefObject<MDXEditorMethods[]>,
  longFormContentContainerEditorRefs: MutableRefObject<HTMLDivElement[]>
) => {
  switch (row.type) {
    case 'LONG_FORM_CONTENT':
      if (row.data.value === null) {
        return (
          <>
            {row.AI?.answers.length > 0 ? (
              <LongFormWrap>
                <PreviewImageWrapper>
                  {row.data.attachments.map((item, index) => (
                    <React.Fragment key={item.asset.assetKey}>
                      {isImageExtension(item.asset.assetKey) ? (
                        <ImageWrapper key={item.asset.assetKey + index}>
                          <ImageItem src={item.asset.url} alt={item.asset.assetKey} />
                        </ImageWrapper>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  ))}
                </PreviewImageWrapper>
                <AiRowCellWrap>
                  <AiIconWrap>
                    <AiAssistantIcon width={28} height={28} fill={theme.font.base} />
                  </AiIconWrap>
                  <AiAnswer>
                    <ShowText>
                      <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
                      {row.AI.answers[0]}
                      {/* {console.log(
                          row?.labelerData?.value &&
                            JSON.parse(row?.labelerData?.value)
                        )} */}
                    </ShowText>
                  </AiAnswer>
                </AiRowCellWrap>
              </LongFormWrap>
            ) : (
              <EmptyTodoRowCell>To Do</EmptyTodoRowCell>
            )}
          </>
        );
      }
      return (
        <LongFormWrap>
          <PreviewImageWrapper>
            {row.data.attachments.map((item, index) => (
              <React.Fragment key={item.asset.assetKey}>
                {isImageExtension(item.asset.assetKey) ? (
                  <ImageWrapper key={item.asset.assetKey + index}>
                    <ImageItem src={item.asset.url} alt={item.asset.assetKey} />
                  </ImageWrapper>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))}
          </PreviewImageWrapper>
          <ShowText>
            {row.data.value && (
              <div ref={(el) => (longFormContentContainerEditorRefs.current[index] = el)}>
                <CustomMDXEditor
                  ref={(el) => (longFormContentEditorRefs.current[index] = el)}
                  markdown={row.data.value || ''}
                  readOnly
                  plugins={[listsPlugin(), headingsPlugin(), linkPlugin(), linkDialogPlugin()]}
                />
              </div>
            )}
          </ShowText>
        </LongFormWrap>
      );
    case 'SHORT_TEXT':
      if (row.data.value === null) {
        return (
          <FieldValueWrapper>
            {row.AI?.answers.length > 0 ? (
              <AiRowCellWrap>
                <AiIconWrap>
                  <AiAssistantIcon width={28} height={28} fill={theme.font.base} />
                </AiIconWrap>
                <AiAnswer>
                  <ShowText>
                    <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
                    {row.AI.answers[0]}
                  </ShowText>
                </AiAnswer>
              </AiRowCellWrap>
            ) : (
              <EmptyTodoRowCell>To Do</EmptyTodoRowCell>
            )}
          </FieldValueWrapper>
        );
      }
      return <FieldValueWrapper>{row.data.value}</FieldValueWrapper>;
    case 'NUMBER':
      if (row.data.value === null && row.data.commentary === null) {
        return (
          <>
            {row.AI?.answers.length > 0 ? (
              <AiRowCellWrap>
                <AiIconWrap>
                  <AiAssistantIcon width={28} height={28} fill={theme.font.base} />
                </AiIconWrap>
                <AiAnswer>
                  <ShowText>
                    <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}: </span>
                    {extractNumbersFromString(row.AI?.answers[0]) && `${extractNumbersFromString(row.AI?.answers[0])} | `}
                    {row.AI.answers[0]}
                  </ShowText>
                </AiAnswer>
              </AiRowCellWrap>
            ) : (
              <EmptyTodoRowCell>To Do</EmptyTodoRowCell>
            )}
          </>
        );
      }
      return (
        <FieldValueWrapper>
          {row.data.value && row.data.commentary
            ? `${row.data.value} | ${row.data.commentary}`
            : (row.data.value && row.data.value) || (row.data.commentary && row.data.commentary)}
        </FieldValueWrapper>
      );
    case 'DOLLAR':
      if (row.data.value === null && row.data.commentary === null) {
        return (
          <>
            {row.AI?.answers.length > 0 ? (
              <AiRowCellWrap>
                <AiIconWrap>
                  <AiAssistantIcon width={28} height={28} fill={theme.font.base} />
                </AiIconWrap>
                <AiAnswer>
                  <ShowText>
                    <span style={{ fontWeight: 700 }}>
                      Suggested
                      {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:{' '}
                    </span>
                    {extractAmount(row.AI?.answers[0]) && `${formatCash(+extractAmount(row.AI?.answers[0]))} | `}
                    {row.AI.answers[0]}
                  </ShowText>
                </AiAnswer>
              </AiRowCellWrap>
            ) : (
              <EmptyTodoRowCell>To Do</EmptyTodoRowCell>
            )}
          </>
        );
      }
      return (
        <FieldValueWrapper>
          {row.data.value && row.data.commentary ? (
            <>
              <FormattingTooltip zIndex={1000}>{+row.data.value}</FormattingTooltip> | {row.data.commentary}
            </>
          ) : (
            (row.data.value && <FormattingTooltip zIndex={1000}>{+row.data.value}</FormattingTooltip>) || (row.data.commentary && row.data.commentary)
          )}
        </FieldValueWrapper>
      );
    case 'PERCENTAGE':
      if (row.data.value === null && row.data.commentary === null) {
        return (
          <>
            {row.AI?.answers.length > 0 ? (
              <AiRowCellWrap>
                <AiIconWrap>
                  <AiAssistantIcon width={28} height={28} fill={theme.font.base} />
                </AiIconWrap>
                <AiAnswer>
                  <ShowText>
                    <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}: </span>
                    {extractPercentage(row.AI?.answers[0]) && `${decimalToPercentage(extractPercentage(row.AI?.answers[0]))} | `}
                    {row.AI.answers[0]}
                  </ShowText>
                </AiAnswer>
              </AiRowCellWrap>
            ) : (
              <EmptyTodoRowCell>To Do</EmptyTodoRowCell>
            )}
          </>
        );
      }
      return (
        <FieldValueWrapper>
          {row.data.value && row.data.commentary
            ? `${decimalToPercentage(row.data.value as string)} | ${row.data.commentary}`
            : (row.data.value && decimalToPercentage(row.data.value as string)) || (row.data.commentary && row.data.commentary)}
        </FieldValueWrapper>
      );
    case 'DATE':
      if (row.data.value === null && row.data.commentary === null) {
        return (
          <>
            {row.AI?.answers.length > 0 ? (
              <AiRowCellWrap>
                <AiIconWrap>
                  <AiAssistantIcon width={28} height={28} fill={theme.font.base} />
                </AiIconWrap>
                <AiAnswer>
                  <ShowText>
                    <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}: </span>
                    {extractAndFormatDate(row.AI?.answers[0]) && `${format(new Date(extractAndFormatDate(row.AI?.answers[0])), 'LLL d, yyyy')} | `}
                    {row.AI.answers[0]}
                  </ShowText>
                </AiAnswer>
              </AiRowCellWrap>
            ) : (
              <EmptyTodoRowCell>To Do</EmptyTodoRowCell>
            )}
          </>
        );
      }
      return (
        <FieldValueWrapper>
          {row.data.value && row.data.commentary
            ? `${format(new Date(row.data.value), 'LLL d, yyyy')} | ${row.data.commentary}`
            : (row.data.value && format(new Date(row.data.value), 'LLL d, yyyy')) || (row.data.commentary && row.data.commentary)}
        </FieldValueWrapper>
      );
  }
};

export const TierSection = ({ sectionData, prevTierStatus, openEditPage, recordStatus, openModalWindow }: TierSectionProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const longFormContentEditorRefs = useRef<(MDXEditorMethods | null)[]>([]);
  const longFormContentContainerEditorRefs: MutableRefObject<(HTMLDivElement | null)[]> = useRef([]);

  useEffect(() => {
    longFormContentEditorRefs?.current?.forEach((editorRef, index) => {
      if (editorRef) {
        editorRef.setMarkdown(sectionData.fields[index].data.value || '');
      }
    });
  }, [sectionData]);

  useEffect(() => {
    longFormContentContainerEditorRefs?.current?.forEach((containerEditorRef) => {
      if (containerEditorRef) {
        const links: NodeListOf<HTMLAnchorElement> = containerEditorRef.querySelectorAll('a');
        links.forEach((link: HTMLAnchorElement) => {
          link.setAttribute('target', '_blank');
        });
      }
    });
  }, [sectionData]);

  const isDisabled =
    sectionData.data.completed ||
    prevTierStatus === TierStatusEnum.REJECTED ||
    recordStatus === RecordStatusEnum.APPROVED ||
    recordStatus === RecordStatusEnum.REJECTED;

  return (
    <>
      {sectionData.fields.map((row, index) => (
        <RowWrapper key={row.id} disabled={isDisabled} isMobile={isMobile}>
          <InfoRowCell>
            {row.data.linked ? <img src="/static/icons/lock-icon.png" /> : row.required ? <RedStarIcon width={10} height={10} /> : <NoRequired />}
            {row.name}
          </InfoRowCell>
          {fieldValue(row, index, theme, longFormContentEditorRefs, longFormContentContainerEditorRefs)}
          {!isDisabled && (
            <>
              {row.linkedField ? (
                <>
                  {row.data.linked ? (
                    <LinkedFieldButton id="editTodoButton" onClick={() => openModalWindow('override-answer', row)} styleType="outline">
                      Override Answer
                    </LinkedFieldButton>
                  ) : (
                    <DiscardAnswerWrap>
                      <LinkedFieldButton id="editTodoButton" onClick={() => openModalWindow('relink-field', row)} styleType="outline">
                        Discard Answer and Relink Field
                      </LinkedFieldButton>
                      <EditTodoButton onClick={() => openEditPage(row.type, row)} id="editTodoButton" isMobile={isMobile}>
                        <EditTodoIcon fill={theme.context.light} />
                      </EditTodoButton>
                    </DiscardAnswerWrap>
                  )}
                </>
              ) : (
                <EditTodoButton onClick={() => openEditPage(row.type, row)} id="editTodoButton" isMobile={isMobile}>
                  <EditTodoIcon fill={theme.context.light} />
                </EditTodoButton>
              )}
            </>
          )}
        </RowWrapper>
      ))}
    </>
  );
};

const CustomMDXEditor = styled(MDXEditor)`
  font-family: Blinker;
  font-size: 16px;
  line-height: 22.4px;
  .mdxeditor-root-contenteditable div {
    padding: 0;
  }
  .mdxeditor-root-contenteditable ul,
  .mdxeditor-root-contenteditable ol {
    padding-left: 26px;
  }
`;

export const RowWrapper = styled.div<{ disabled: boolean; isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '20px' : '0')};
  padding: 28px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};

  &:hover #editTodoButton {
    opacity: 1;
  }

  &:hover {
    background-color: ${({ disabled, theme }) => (disabled ? 'none' : theme.layer[2])};
    transition: background-color 100ms ease;
  }
`;

export const InfoRowCell = styled.div`
  max-width: 380px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.strong};
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const NoRequired = styled.div`
  width: 10px;
`;

const EditTodoButton = styled.div<{ isMobile: boolean }>`
  background-color: ${({ theme }) => theme.action.default};
  border-radius: 4px;
  padding: 12px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
`;

const AiRowCellWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const AiIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
`;

const AiAnswer = styled.div`
  padding-left: 10px;
  border-left: 1px solid ${({ theme }) => theme.border.base};
`;

export const EmptyTodoRowCell = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.weak};
`;

export const FieldValueWrapper = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.font.base};
  max-width: 680px;
  width: 100%;
`;

const LongFormWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PreviewImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ImageWrapper = styled.div`
  width: 183px;
  height: 120px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.context.success};
  border-radius: 5px;
  padding: 10px;
`;

const ImageItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const DiscardAnswerWrap = styled.div`
  display: flex;
  gap: 20px;
  margin-left: auto;
  padding-left: 10px;
`;

const LinkedFieldButton = styled(Button)`
  opacity: 0;
  white-space: nowrap;
`;
