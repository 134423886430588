import client from '@/apollo-client';
import FileNotSupported from '@/components/dueDiligence-page/modals/fileNotSupported';
import { ScrollTo } from '@/components/fat-dealCatalog-page/types';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { updateLegalEntityMutation } from '@/components/fat-investors-page/fat-addInvestors/queries';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import { useMutation } from '@apollo/client';
import { parse } from 'csv-parse/sync';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Loading from '../fat-modals/loading';
import {
  LIST_ENTITIES_QUERY_BASIC,
  LIST_INVESTMENT_VEHICLES_QUERY,
  createInvestmentVehicleMutation,
  importInitialCommitmentsMutation
} from '../queries';
import { IEntityCodesTableData, ISeriesCodesTableData, MissingIdError } from '../types';
import { removeDuplicates } from '../utils';
import { allocationsColumnsSectionData, allocationsDescriptionSectionData, allocationsSteps } from './constants';
import { ResolveAllocationConflicts } from './resolveConflicts';
import { AllocationConflicts } from './steps/allocationConflicts';
import { EntityCodes } from './steps/entityCodes';
import { SeriesCodes } from './steps/seriesCodes';
import { UploadDataFile } from './steps/uploadDataFile';
import {
  AllocationComparison,
  IAllocationConflictsTableData,
  ImportAllocationsFileData,
  ImportInitialCommitments,
  InitialImportCommitmentSummary,
  MismatchedAllocationError
} from './types';

interface ImportAllocationsProps {
  handleCloseImportAllocations: () => void;
  backToTitle?: string;
}

const fileExtensions = ['CSV'];

export const ImportAllocations = ({ handleCloseImportAllocations, backToTitle }: ImportAllocationsProps) => {
  const { isMobile, isTablet } = useResponsive();
  const [importSteps, setImportSteps] = useState(allocationsSteps);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'not-supported' });
  const [drag, setDrag] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isResolveConflicts, setIsResolveConflicts] = useState(false);
  const [resolveConflictsData, setResolveConflictsData] = useState<IAllocationConflictsTableData | null>(null);

  const [entityCodesTableData, setEntityCodesTableData] = useState<IEntityCodesTableData[]>([]);
  const [seriesCodesTableData, setSeriesCodesTableData] = useState<ISeriesCodesTableData[]>([]);
  const [allocationConflictsTableData, setAllocationConflictsTableData] = useState<IAllocationConflictsTableData[]>([]);
  const [showAllocationConflictsTable, setShowAllocationConflictsTable] = useState(false);

  const [entityCodesErrors, setEntityCodesErrors] = useState<MissingIdError[]>([]);
  const [seriesCodesErrors, setSeriesCodesErrors] = useState<MissingIdError[]>([]);
  const [allocationConflictsErrors, setAllocationConflictsErrors] = useState<MismatchedAllocationError[]>([]);
  const [sort, setSort] = useState<ISort>({
    key: 'ENTITY',
    asc: true
  });
  const [search, setSearch] = useState('');
  const [primaryFilter, setPrimaryFilter] = useState('Showing Conflict Only');

  const [isValidFile, setIsValidFile] = useState(true);
  const [recordsToUpdate, setRecordsToUpdate] = useState<any>([]);
  const [fileData, setFileData] = useState<ImportAllocationsFileData[]>([]);
  const [scrollTo, setScrollTo] = useState<ScrollTo>({ y: 0, makeScroll: false });

  const [summary, setSummary] = useState<InitialImportCommitmentSummary>();

  const [updateEntity] = useMutation(updateLegalEntityMutation);
  const [createInvestmentVehicle] = useMutation(createInvestmentVehicleMutation);
  const [importInitialCommitments, { loading: importInitialCommitmentsMutationLoading }] = useMutation<{
    importInitialCommitments: ImportInitialCommitments;
  }>(importInitialCommitmentsMutation, {
    onError: () => {
      setFile(null);
      setIsValidFile(false);
    }
  });

  const allowFileExtensions = (files: FileList | null) => {
    return Array.from(files || []).filter((file: File) => {
      const fileExt = file.name.split('.').pop()?.toLowerCase();
      if ([...fileExtensions.map((item) => item.toLowerCase())].includes(fileExt || '')) {
        return file;
      }
    });
  };

  const importFile = async (file: File) => {
    setIsValidFile(true);

    const text = await file.text();
    const records = parse(text, {
      columns: true,
      skip_empty_lines: true,
      trim: true
    });

    const gruppedFileData = records.map((record: any) => ({
      entityName: record['Entity Name'],
      investmentCode: record['Series Code'],
      entityCode: record['Entity Code'],
      tradeDate: record['Trade Date'],
      netAmount: Number(record['NetAmount']),
      advisoryFirm: record['Advisory Firm'],
      seriesName: record['Series Name']
    }));
    setFileData(gruppedFileData);

    const recordsToUpdate = records.map((record: any) => ({
      ...(record['Series Code'] && { investmentCode: record['Series Code'] }),
      ...(record['Entity Code'] && { entityCode: record['Entity Code'] }),
      ...(record['Trade Date'] && { tradeDate: record['Trade Date'] }),
      ...(record['NetAmount'] && { netAmount: Number(record['NetAmount']) }),
      entityName: record['Entity Name'],
      advisoryFirm: record['Advisory Firm']
    }));
    setRecordsToUpdate(recordsToUpdate);

    const { data } = await importInitialCommitments({
      variables: {
        data: {
          save: false,
          data: recordsToUpdate
        }
      }
    });

    const newEntityCodesErrors = data?.importInitialCommitments.errors?.filter((error) =>
      error.message.includes('Missing legal entity for entity code')
    );

    await setEntityCodesStepData(gruppedFileData, newEntityCodesErrors ?? []);
  };

  const onDropHandler = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;

    setDrag(false);
    if (!allowFileExtensions(uploadFiles).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      setFile(uploadFiles[0]);
      importFile(uploadFiles[0]);
    }
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (!allowFileExtensions(uploadFiles).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      setFile(uploadFiles[0]);
      importFile(uploadFiles[0]);
    }
  };

  const completeImport = async () => {
    setModalWindow({ isOpen: true, type: 'loading' });

    const { data } = await importInitialCommitments({
      variables: {
        data: {
          save: true,
          data: recordsToUpdate
        }
      }
    });

    if (data?.importInitialCommitments.status === 'done') {
      handleCloseImportAllocations();
      setModalWindow({ isOpen: false, type: 'loading' });
      return;
    }

    await setAllocationConflictsStepData(
      data?.importInitialCommitments.allocationComparisons ?? null,
      data?.importInitialCommitments.mismatchedAllocationErrors ?? []
    );
    setModalWindow({ isOpen: false, type: 'loading' });
  };

  const nextStep = () => {
    const currentStepIndex = importSteps.findIndex((step) => step.status === 'IN_PROGRESS');
    if (currentStepIndex === importSteps.length - 1) return;

    const updatedImportSteps = importSteps.map((step, index) => {
      if (currentStepIndex === index) {
        return { ...step, status: 'COMPLETED' };
      }
      if (currentStepIndex + 1 === index) {
        return { ...step, status: 'IN_PROGRESS' };
      }

      return step;
    });

    setImportSteps(updatedImportSteps);
  };

  const setEntityCodesStepData = async (fileData: ImportAllocationsFileData[], errors: MissingIdError[]) => {
    const { data: listEntities } = await client.query({
      query: LIST_ENTITIES_QUERY_BASIC,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          limit: 1000
        }
      }
    });

    const updatedEntityCodesTableData = fileData.map((record) => {
      const errorEntityCode = errors.find((entityCode) => entityCode.id === record.entityCode);
      const matchedEntity = listEntities.listEntities.data.find((item: any) => item.importId === record.entityCode);

      return {
        entityName: record.entityName,
        entityCode: record.entityCode,
        advisoryFirm: record.advisoryFirm,
        entityAssignment: errorEntityCode || !matchedEntity ? 'Select An Entity' : `${matchedEntity.entityName} (${matchedEntity.tenant.name})`,
        entityStatus: errorEntityCode ? 'Required' : 'Assigned',
        changed: false,
        entityId: null
      };
    });

    setEntityCodesErrors(removeDuplicates(errors, 'id'));
    setEntityCodesTableData(removeDuplicates(updatedEntityCodesTableData, 'entityCode'));
  };

  const setSeriesCodesStepData = async (fileData: ImportAllocationsFileData[], errors: MissingIdError[]) => {
    const seriesCodes = recordsToUpdate.map((record: any) => record.investmentCode);

    const { data: investmentVehicleData } = await client.query({
      query: LIST_INVESTMENT_VEHICLES_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        seriesCodes: seriesCodes
      }
    });

    const updatedSeriesCodesTableData = fileData.map((record) => {
      const errorSeriesCode = errors.find((seriesCode) => seriesCode.id === record.investmentCode);
      const matchedInvestment = investmentVehicleData.listInvestmentVehicles.find((item: any) => item.id === record.investmentCode);

      return {
        investmentCode: record.investmentCode,
        seriesName: record.seriesName,
        seriesAssignment: errorSeriesCode || !matchedInvestment ? 'Select A Strategy' : matchedInvestment.investment.name,
        seriesStatus: errorSeriesCode ? 'Required' : 'Assigned',
        changed: false,
        investmentId: null
      };
    });

    setSeriesCodesErrors(removeDuplicates(errors, 'id'));
    setSeriesCodesTableData(removeDuplicates(updatedSeriesCodesTableData, 'investmentCode'));
  };

  const setAllocationConflictsStepData = async (allocationComparisons: AllocationComparison[] | null, errors: MismatchedAllocationError[]) => {
    const allocationEntities = allocationComparisons?.map((item) => ({
      investmentId: item.investmentId,
      entityName: item.legalEntity?.entityName ?? '',
      advisoryFirm: item.legalEntity?.tenant?.name ?? '',
      investmentName: item.investment?.name ?? '',
      importTotal: item.importTotal,
      importCount: item.importCount,
      committedTotal: item.committedTotal,
      pendingAmount: item.pendingAmount,
      importAllocations: item.importAllocations,
      legalEntityId: item.legalEntityId,
      committedAllocations: item.existingAllocations,
      pendingAllocations: item.pendingAllocations,
      existingEntityCode: item.existingEntityCode,
      existingInvestmentCodes: item.existingInvestmentCodes,
      validated: item.validated
    }));
    setAllocationConflictsTableData(allocationEntities as any);
    setAllocationConflictsErrors(errors);
  };

  const moveToSeriesCodesStep = async () => {
    setModalWindow({ isOpen: true, type: 'loading' });

    const changedRows = entityCodesTableData.filter((row) => row.changed && row.entityId);
    for await (const row of changedRows) {
      await updateEntity({
        variables: {
          data: {
            id: row.entityId,
            importId: row.entityCode
          }
        }
      });
    }

    const { data } = await importInitialCommitments({
      variables: {
        data: {
          save: false,
          data: recordsToUpdate
        }
      }
    });

    const newEntityCodesErrors = data?.importInitialCommitments.errors?.filter((error) =>
      error.message.includes('Missing legal entity for entity code')
    );

    if (newEntityCodesErrors?.length) {
      await setEntityCodesStepData(fileData, newEntityCodesErrors);
      setModalWindow({ isOpen: false, type: 'loading' });
      return;
    }

    const newSeriesCodesErrors = data?.importInitialCommitments.errors?.filter((error) =>
      error.message.includes('Missing investment vehicle for investment code')
    );

    await setSeriesCodesStepData(fileData, newSeriesCodesErrors ?? []);
    setModalWindow({ isOpen: false, type: 'loading' });
    nextStep();
  };

  const moveToAllocationConflictsStep = async () => {
    setModalWindow({ isOpen: true, type: 'loading' });

    const changedRows = seriesCodesTableData.filter((row) => row.changed && row.investmentId);
    for await (const row of changedRows) {
      await createInvestmentVehicle({
        variables: {
          data: {
            seriesCode: row.investmentCode,
            seriesName: row.seriesName?.replace(` - ${row.investmentCode}`, ''),
            investmentId: row.investmentId
          }
        }
      });
    }

    const { data } = await importInitialCommitments({
      variables: {
        data: {
          save: false,
          data: recordsToUpdate
        }
      }
    });

    const newSeriesCodesErrors = data?.importInitialCommitments.errors?.filter((error) =>
      error.message.includes('Missing investment vehicle for investment code')
    );

    if (newSeriesCodesErrors?.length) {
      await setSeriesCodesStepData(fileData, newSeriesCodesErrors);
      setModalWindow({ isOpen: false, type: 'loading' });
      return;
    }

    await setAllocationConflictsStepData(
      data?.importInitialCommitments.allocationComparisons ?? null,
      data?.importInitialCommitments.mismatchedAllocationErrors ?? []
    );
    if (data?.importInitialCommitments?.summary) {
      setSummary(data.importInitialCommitments.summary);
    }
    setModalWindow({ isOpen: false, type: 'loading' });
    nextStep();
  };

  const refetchAllocationConflicts = async () => {
    const { data } = await importInitialCommitments({
      variables: {
        data: {
          save: false,
          data: recordsToUpdate
        }
      }
    });

    await setAllocationConflictsStepData(
      data?.importInitialCommitments.allocationComparisons ?? null,
      data?.importInitialCommitments?.mismatchedAllocationErrors ?? []
    );

    if (data?.importInitialCommitments?.summary) {
      setSummary(data.importInitialCommitments.summary);
    }
  };

  const startOver = () => {
    if (!file) return;
    importFile(file);
    setImportSteps(allocationsSteps);
    setShowAllocationConflictsTable(false);
  };

  const openResolveAllocationConflicts = (row: IAllocationConflictsTableData) => {
    setScrollTo({ y: window.scrollY, makeScroll: true });
    setResolveConflictsData(row);
  };

  const handleCloseResolveAllocationConflicts = () => {
    setResolveConflictsData(null);
    scrollTo.makeScroll && setTimeout(() => window.scrollTo({ top: scrollTo.y, behavior: 'smooth' }), 10);
  };

  const updateResolveConflictsData = (data: any) => {
    setResolveConflictsData(data);
  };

  useEffect(() => {
    if (resolveConflictsData) {
      setIsResolveConflicts(true);
    } else {
      setIsResolveConflicts(false);
    }
  }, [resolveConflictsData]);

  return isResolveConflicts ? (
    <ResolveAllocationConflicts
      resolveConflictsData={resolveConflictsData}
      updateResolveConflictsData={updateResolveConflictsData}
      handleClose={handleCloseResolveAllocationConflicts}
      refetchAllocationConflicts={refetchAllocationConflicts}
      backToTitle="Import Allocations"
    />
  ) : (
    <>
      {modalWindow.type === 'not-supported' && (
        <FileNotSupported isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ ...modalWindow, isOpen: false })} />
      )}
      {modalWindow.type === 'loading' && <Loading isOpen={modalWindow.isOpen} />}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleCloseImportAllocations} backToTitle={backToTitle} />} />
        <PageTitle title="Import Allocations" />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          {importSteps[0].status === 'IN_PROGRESS' && (
            <UploadDataFile
              importSteps={importSteps}
              file={file}
              handleUploadFile={handleUploadFile}
              onDropHandler={onDropHandler}
              drag={drag}
              setDrag={setDrag}
              handleClose={handleCloseImportAllocations}
              nextStep={nextStep}
              loading={importInitialCommitmentsMutationLoading && !modalWindow.isOpen}
              isValidFile={isValidFile}
              descriptionSection={allocationsDescriptionSectionData}
              columnsSection={allocationsColumnsSectionData}
              deleteFile={() => {
                setFile(null);
              }}
            />
          )}
          {importSteps[1].status === 'IN_PROGRESS' && (
            <EntityCodes
              importSteps={importSteps}
              startOver={startOver}
              nextStep={moveToSeriesCodesStep}
              tableData={entityCodesTableData}
              setTableData={setEntityCodesTableData}
              errorEntityCode={entityCodesErrors}
              setErrors={setEntityCodesErrors}
            />
          )}
          {importSteps[2].status === 'IN_PROGRESS' && (
            <SeriesCodes
              importSteps={importSteps}
              startOver={startOver}
              nextStep={moveToAllocationConflictsStep}
              tableData={seriesCodesTableData}
              setTableData={setSeriesCodesTableData}
              seriesCodesError={seriesCodesErrors}
              setErrors={setSeriesCodesErrors}
            />
          )}
          {importSteps[3].status === 'IN_PROGRESS' && (
            <AllocationConflicts
              importSteps={importSteps}
              startOver={startOver}
              nextStep={completeImport}
              openResolveAllocationConflicts={openResolveAllocationConflicts}
              tableData={allocationConflictsTableData}
              allocationConflictsErrors={allocationConflictsErrors}
              showTable={showAllocationConflictsTable}
              setShowTable={setShowAllocationConflictsTable}
              sort={sort}
              setSort={setSort}
              search={search}
              setSearch={setSearch}
              primaryFilter={primaryFilter}
              setPrimaryFilter={setPrimaryFilter}
              summary={summary}
            />
          )}
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;
