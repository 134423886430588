import { gql } from '@apollo/client';

export const GET_LEGAL_ENTITY_QUERY = gql`
  query getLegalEntity($data: getLegalEntityDto!) {
    getLegalEntity(data: $data) {
      id
      entityName
      totalEntityValue
      totalCommitments
      investmentCount
      committedAllocations {
        id
      }
      outstandingAllocations {
        id
      }
    }
  }
`;

export const ClientDetailsSummary = gql`
  query ClientEntityDetailsSummary($id: String!, $includeRecommendation: Boolean, $sort: String, $order: String) {
    ClientEntityDetailsSummary(id: $id, includeRecommendation: $includeRecommendation, sort: $sort, order: $order) {
      name
      exportId
      entityCRMId
      totalEntitiesValue
      privatePercentage
      targetOCRatio
      currentOCRatio
      ignoreBiteSize
      ignoreAllocationTargets
      notes {
        title
        notes
        notesDate
      }
      alwaysShow
      biteSize {
        fundBiteSize
        directBiteSize
      }
      onHold
      custodied
      ubtiBlocker
      impact
      sectorsToAvoid
      progressBarValues {
        economicExposure
        totalCapitalTarget
        capitalTarget
        isGoalAchieved
      }
      summary {
        privateRealAssetPercentage
        privateEquityPercentage
        privateCreditPercentage
        diversifyingStrategiesPercentage
        privateEquityTargetAllocations
        privateCreditTargetAllocations
        privateRealAssetTargetAllocations
        diversifyingStrategiesTargetAllocations
        economicExposureForPrivateCredit
        economicExposureForPrivateRealAssets
        economicExposureForPrivateEquity
        economicExposureForDiversifyingStrategies
        privateEquityCapacity
        privateCreditCapacity
        privateRealAssetsCapacity
        diversifyingStrategiesCapacity
        overallCapacity
      }
    }
  }
`;

export const GET_LEGAL_ENTITY_SUMMARY = gql`
  query LegalEntity($id: String!, $includingProposed: Boolean) {
    legalEntity(id: $id, includingProposed: $includingProposed) {
      entityName
      totalEntityValue
      privatePercentage
      alwaysShow
      targetOCRatio
      currentOCRatio
      ignoreBiteSize
      ignoreAllocationTargets
      onHold
      custodied
      ubtiBlocker
      impact
      sectorsToAvoid
      defaultBiteSize1
      defaultBiteSize2
      notes {
        title
        notes
        notesDate
      }
      progressBarValues {
        economicExposure
        totalCapitalTarget
        capitalTarget
        isGoalAchieved
      }
      assetClassTargets(includingProposed: $includingProposed) {
        id
        name
        percentage
        targetAllocation
        economicExposure
        capacity
        pacingTarget {
          startDate
          cumulativePacingTarget
          cumulativeCommitments
          remainingPacingTarget
          pacingTargetThroughDate
        }
        subAssetClasses {
          id
          name
          percentage
          targetAllocation
          economicExposure
          capacity
          pacingTarget {
            startDate
            cumulativePacingTarget
            cumulativeCommitments
            remainingPacingTarget
            pacingTargetThroughDate
          }
        }
      }
    }
  }
`;

export const ClientDetailsCommitments = gql`
  query ClientEntityDetailsCommitments($id: String!, $includeRecommendation: Boolean, $sort: String, $order: String, $search: String) {
    ClientEntityDetailsCommitments(id: $id, includeRecommendation: $includeRecommendation, sort: $sort, order: $order, search: $search) {
      name
      totalEntitiesValue
      privatePercentage
      targetOCRatio
      currentOCRatio
      biteSize {
        fundBiteSize
        directBiteSize
      }
      notes {
        title
        notes
        notesDate
      }
      alwaysShow
      onHold
      custodied
      ubtiBlocker
      impact
      sectorsToAvoid
      privateRealAssetPercentage
      privateEquityPercentage
      privateCreditPercentage
      diversifyingStrategiesPercentage
      privateEquityCapacity
      privateCreditCapacity
      privateRealAssetsCapacity
      diversifyingStrategiesCapacity
      commitments {
        id
        investmentId
        name
        committedCapital
        capitalCalled
        capitalRemaining
        distributionsToDate
        marketValue
        status
        assetClass {
          id
          name
        }
        subAssetClass {
          id
          name
        }
        moreInfo {
          id
          managerId
          assetClass
          nextClose
          finalClose
          investorDocsDue
          minimum
          custodied
          ubtiBlocker
          targetIRR
          targetYield
        }
        valuationAmount
        valuationDate
        aggregateCallsAndDistributions
      }
    }
  }
`;

export const ClientsDetailsAllocations = gql`
  query ClientEntityDetailsAllocations($id: String!, $includeRecommendation: Boolean, $sort: String, $order: String) {
    ClientEntityDetailsAllocations(id: $id, includeRecommendation: $includeRecommendation, sort: $sort, order: $order) {
      name
      totalEntitiesValue
      privatePercentage
      targetOCRatio
      currentOCRatio
      notes {
        title
        notes
        notesDate
      }
      biteSize {
        fundBiteSize
        directBiteSize
      }
      ubtiBlocker
      custodied
      alwaysShow
      onHold
      impact
      sectorsToAvoid
      privateRealAssetPercentage
      privateEquityPercentage
      privateCreditPercentage
      diversifyingStrategiesPercentage
      privateEquityCapacity
      privateCreditCapacity
      privateRealAssetsCapacity
      diversifyingStrategiesCapacity
      allocations {
        investmentId
        name
        suitability
        status
        finalClose
        investorDocsDue
        assetClass {
          id
          name
        }
        subAssetClass {
          id
          name
        }
        minimum
        suitabilityCriteria {
          label
          value
        }
        proposalType
        committedCapital
      }
    }
  }
`;

export const LIST_ENTITY_TRANSACTIONS = gql`
  query ListEntityTransactions(
    $legalEntityId: String!
    $investmentId: String
    $page: Int!
    $rowsPerPage: Int!
    $sortBy: ListEntityCommitmentTransactionSortBy
    $order: String
    $search: String
    $transactionTypeFilter: [CommitmentTransactionType!]
  ) {
    ListEntityTransactions(
      legalEntityId: $legalEntityId
      investmentId: $investmentId
      page: $page
      rowsPerPage: $rowsPerPage
      sortBy: $sortBy
      order: $order
      search: $search
      transactionTypeFilter: $transactionTypeFilter
    ) {
      total
      page
      perPage
      investments {
        name
        id
      }
      filters {
        count
        value
        name
      }
      data {
        date
        strategy
        transactionType
        amount
        totalAmount
        distributionsToDate
        remainingCapital
        marketValue
      }
    }
  }
`;

export const updateEntityMutation = gql`
  mutation updateEntity($data: LegalEntityUpdateDto!) {
    updateEntity(data: $data) {
      id
      notes
    }
  }
`;

export const LIST_SECTORS_TO_AVOID_QUERY = gql`
  query listSectorsToAvoid {
    listSectorsToAvoid
  }
`;

export const autoPlanMinMutation = gql`
  mutation autoPlan($input: AutoPlanDto!) {
    autoPlan(input: $input) {
      startingPortfolioValue
      drawdownTarget
      startingAllocation
      assetClassDrawdownTargets {
        actuals {
          name
          portfolioPercentage
          privateCapitalPercentage
          subAssetClassDrawdownTargets {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
        }
        targets {
          name
          portfolioPercentage
          privateCapitalPercentage
          subAssetClassDrawdownTargets {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
        }
      }
      drawdownTargets {
        actuals {
          drawdown {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
          evergreen {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
        }
        targets {
          drawdown {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
          evergreen {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
        }
      }
    }
  }
`;

export const autoPlanMutation = gql`
  mutation autoPlan($input: AutoPlanDto!) {
    autoPlan(input: $input) {
      id
      name
      startDate
      startingPortfolioValue
      drawdownTarget
      startingAllocation
      growthRate
      active
      forecastSummary {
        assetClassForecasts {
          name
          target
          asOfForecast
          fiveYearForecast
          tenYearForecast
          subAssetClassForecasts {
            name
            target
            asOfForecast
            fiveYearForecast
            tenYearForecast
          }
        }
        fiveYearDate
        tenYearDate
      }
      drawdownTargets {
        actuals {
          drawdown {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
          evergreen {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
        }
        targets {
          drawdown {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
          evergreen {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
        }
      }
      planAllocations {
        date
        assetClassAllocations {
          assetClassId
          name
          allocation
          subAssetClasses {
            subAssetClassId
            name
            allocation
          }
        }
      }
    }
  }
`;

export const createCommitmentPlanMutation = gql`
  mutation CreateCommitmentPlan($input: CreateCommitmentPlanDto!) {
    createCommitmentPlan(input: $input) {
      id
      name
      startDate
      startingPortfolioValue
      drawdownTarget
      startingAllocation
      growthRate
      active
      planAllocations {
        date
        assetClassAllocations {
          assetClassId
          name
          allocation
          subAssetClasses {
            subAssetClassId
            name
            allocation
          }
        }
      }
    }
  }
`;

export const updateCommitmentPlanMutation = gql`
  mutation UpdateCommitmentPlan($input: UpdateCommitmentPlanInput!) {
    updateCommitmentPlan(input: $input) {
      id
      name
      startDate
      startingPortfolioValue
      drawdownTarget
      startingAllocation
      growthRate
      active
      planAllocations {
        date
        assetClassAllocations {
          assetClassId
          name
          allocation
          subAssetClasses {
            subAssetClassId
            name
            allocation
          }
        }
      }
    }
  }
`;

export const FORECAST_SUMMARY_QUERY = gql`
  query forecastSummary($legalEntityId: String!, $allocations: [CreateAllocationsByDateDto!]!, $startDate: DateTime!, $growthRate: Float!) {
    forecastSummary(legalEntityId: $legalEntityId, allocations: $allocations, startDate: $startDate, growthRate: $growthRate) {
      assetClassForecasts {
        name
        target
        asOfForecast
        fiveYearForecast
        tenYearForecast
        subAssetClassForecasts {
          name
          target
          asOfForecast
          fiveYearForecast
          tenYearForecast
        }
      }
    }
  }
`;

export const COMMITMENT_PLANS_QUERY = gql`
  query commitmentPlans($input: GetCommitmentPlansInput!) {
    commitmentPlans(input: $input) {
      total
      limit
      offset
      data {
        id
        name
        startDate
        startingPortfolioValue
        drawdownTarget
        startingAllocation
        growthRate
        active
        autoPlanStatus
      }
    }
  }
`;

export const GET_TOTAL_LIST_COMMITMENT_PLANS_QUERY = gql`
  query commitmentPlans($input: GetCommitmentPlansInput!) {
    commitmentPlans(input: $input) {
      total
    }
  }
`;

export const COMMITMENT_PLANS_MIN_QUERY = gql`
  query commitmentPlans($input: GetCommitmentPlansInput!) {
    commitmentPlans(input: $input) {
      total
      data {
        id
        name
        active
      }
    }
  }
`;

export const GET_AUTO_PLAN_STATUS_QUERY = gql`
  query commitmentPlan($id: String!) {
    commitmentPlan(id: $id) {
      autoPlanStatus
    }
  }
`;

export const COMMITMENT_PLAN_QUERY = gql`
  query commitmentPlan($id: String!) {
    commitmentPlan(id: $id) {
      id
      name
      startDate
      startingPortfolioValue
      drawdownTarget
      startingAllocation
      growthRate
      active
      autoPlanStatus
      assetClassDrawdownTargets {
        actuals {
          name
          portfolioPercentage
          privateCapitalPercentage
          subAssetClassDrawdownTargets {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
        }
        targets {
          name
          portfolioPercentage
          privateCapitalPercentage
          subAssetClassDrawdownTargets {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
        }
      }
      planAllocations {
        date
        assetClassAllocations {
          assetClassId
          name
          allocation
          subAssetClasses {
            subAssetClassId
            name
            allocation
          }
        }
      }
      forecastSummary {
        assetClassForecasts {
          name
          target
          asOfForecast
          fiveYearForecast
          tenYearForecast
          subAssetClassForecasts {
            name
            target
            asOfForecast
            fiveYearForecast
            tenYearForecast
          }
        }
        fiveYearDate
        tenYearDate
      }
      drawdownTargets {
        actuals {
          drawdown {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
          evergreen {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
        }
        targets {
          drawdown {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
          evergreen {
            name
            portfolioPercentage
            privateCapitalPercentage
          }
        }
      }
      forecast {
        netContributions {
          date
          value
          actual
        }
        netDistributions {
          date
          value
          actual
        }
        netCommitments {
          date
          value
          actual
        }
        uncalledCapital {
          date
          value
          actual
        }
        totalNAVs {
          date
          value
          actual
        }
        cumulativeContributions {
          date
          value
          actual
        }
        cumulativeDistributions {
          date
          value
          actual
        }
        existingCommitmentNAVs {
          name
          data {
            date
            value
            actual
          }
        }
        existingCommitmentDistributions {
          name
          data {
            date
            value
            actual
          }
        }
        existingCommitmentContributions {
          name
          data {
            date
            value
            actual
          }
        }
        assetClassContributions {
          name
          date
          value
          actual
          subAssetClasses {
            name
            date
            value
            actual
          }
        }
        assetClassDistributions {
          name
          date
          value
          actual
          subAssetClasses {
            name
            date
            value
            actual
          }
        }
        assetClassNAVs {
          name
          date
          value
          actual
          subAssetClasses {
            name
            date
            value
            actual
          }
        }
        assetClassPercentages {
          name
          date
          value
          actual
          subAssetClasses {
            name
            date
            value
            actual
          }
        }
      }
    }
  }
`;

export const deleteCommitmentPlanMutation = gql`
  mutation deleteCommitmentPlan($id: String!) {
    deleteCommitmentPlan(id: $id)
  }
`;
