import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { OverdueIcon } from '@/assets/icons/info-icons/overdue';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import Button from '@/components/fat-basicComponents/button';
import { Loader } from '@/components/loaders/loader';
import { initialValuesFromTable } from '@/components/table/constants';
import { IItems, ISort, SORT_ORDER } from '@/components/table/types';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { dateFormatter } from '@/utils/dateFormatter';
import { NetworkStatus, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { MANAGER_UPDATES_EDITABLE_ROLES } from '../constatnts';
import { isMoreThan90DaysInPast } from '../utils';
import AddUpdate from './modals/addUpdate';
import ChangeOwner from './modals/changeOwner';
import DeleteUpdate from './modals/deleteUpdate';
import DownloadPDF from './modals/downloadPDF';
import { GET_DOCUMENT_RECORD_QUERY, GET_LIST_DOCUMENT_RECORDS_TOTAL_QUERY, LIST_DOCUMENT_RECORDS_QUERY } from './queries';
import { AllUpdatesSection } from './sections/allUpdates';
import { LatestUpdateSection } from './sections/latestUpdate';
import { DOCUMENT_RECORD_STATUS_ENUM, IDocumentRecordEntity, IGetDocumentRecordQuery, IListDocumentRecordsQuery } from './types';

interface UpdatesTabProps {
  managerId: string | null;
  backToTitle: string;
}

export const UpdatesTab = ({ managerId, backToTitle }: UpdatesTabProps) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const { user } = useAuth();
  const params = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const key = searchParams.get('key') || 'NAME';
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const primaryFilter = searchParams.get('primaryFilter') || 'All';

  const [canUserEdit, setCanUserEdit] = useState(false);
  const [latestUpdateId, setLatestUpdateId] = useState<string | null>(null);
  const [showZeroState, setShowZeroState] = useState(false);
  const [updatesTableData, setUpdatesTableData] = useState<IDocumentRecordEntity[]>([]);
  const [currentRow, setCurrentRow] = useState<IDocumentRecordEntity | null>(null);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'add-update'
  });

  useEffect(() => {
    if (MANAGER_UPDATES_EDITABLE_ROLES.some((role) => user.roles.includes(role)) && user.tenant.type === 'fundManager') {
      setCanUserEdit(true);
      return;
    }
    onChangePrimaryFilter('Published');
  }, [user]);

  const { refetch: latestUpdateRefetch, networkStatus: latestUpdateNetworkStatus } = useQuery<{ listDocumentRecords: IListDocumentRecordsQuery }>(
    LIST_DOCUMENT_RECORDS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      skip: !params?.strategyId,
      variables: {
        data: {
          investmentId: params?.strategyId,
          sortBy: 'STATUS_UPDATED_DATE',
          sortDirection: SORT_ORDER.DESC,
          statusFilter: 'Published',
          limit: 1
        }
      },
      onCompleted: (data) => {
        setLatestUpdateId(data.listDocumentRecords.records[0]?.id ?? null);
      },
      onError: (error) => {
        console.error(error);
      }
    }
  );

  const { data: documentRecordData, networkStatus: documentRecordNetworkStatus } = useQuery<{ getDocumentRecord: IGetDocumentRecordQuery }>(
    GET_DOCUMENT_RECORD_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      skip: !latestUpdateId,
      variables: {
        id: latestUpdateId
      },
      onError: (error) => {
        console.error(error);
      }
    }
  );

  const { loading: getListDocumentRecordsTotalLoading } = useQuery<{ listDocumentRecords: IListDocumentRecordsQuery }>(
    GET_LIST_DOCUMENT_RECORDS_TOTAL_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      skip: !params?.strategyId,
      variables: {
        data: {
          investmentId: params?.strategyId,
          sortBy: 'NAME',
          sortDirection: SORT_ORDER.ASC
        }
      },
      onCompleted: (data) => {
        setShowZeroState(!data.listDocumentRecords.total);
      },
      onError: (error) => {
        console.error(error);
      }
    }
  );

  const {
    data: listDocumentRecordsData,
    refetch: listDocumentRecordsRefetch,
    loading: listDocumentRecordsLoading,
    networkStatus: listDocumentRecordsNetworkStatus
  } = useQuery<{ listDocumentRecords: IListDocumentRecordsQuery }>(LIST_DOCUMENT_RECORDS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !params?.strategyId,
    variables: {
      data: {
        investmentId: params?.strategyId,
        sortBy: key,
        sortDirection: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        statusFilter:
          primaryFilter === 'All'
            ? undefined
            : Object.keys(DOCUMENT_RECORD_STATUS_ENUM).find(
                (key) => DOCUMENT_RECORD_STATUS_ENUM[key as keyof typeof DOCUMENT_RECORD_STATUS_ENUM] === primaryFilter
              ),
        limit: Number(limit),
        offset: Number(offset)
      }
    },
    onCompleted: (data) => {
      setUpdatesTableData(data.listDocumentRecords.records);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const onChangePrimaryFilter = (filter: string) => {
    setSearchParams(
      (prev) => {
        prev.set('primaryFilter', filter);
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const openEditManagerUpdatePage = (updateId: string) => {
    navigate(`edit/${updateId}?backToTitle=${backToTitle}`);
  };

  const openModalWindow = (type: string, row?: IDocumentRecordEntity) => {
    setModalWindow({ ...modalWindow, isOpen: true, type });
    if (row) {
      setCurrentRow(row);
    }
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
    setCurrentRow(null);
  };

  const dropdownActions = (row: IDocumentRecordEntity): IItems[] => {
    return [
      {
        name: 'Open',
        onClick: () => openEditManagerUpdatePage(row.id)
      },
      {
        name: 'Download',
        onClick: () => openModalWindow('download-pdf', row)
      },
      ...(canUserEdit
        ? [
            {
              name: 'Change Owner',
              onClick: () => openModalWindow('change-owner', row)
            }
          ]
        : []),
      ...(canUserEdit
        ? [
            {
              name: 'Delete',
              onClick: () => openModalWindow('delete-update', row)
            }
          ]
        : [])
    ];
  };

  if (
    getListDocumentRecordsTotalLoading ||
    latestUpdateNetworkStatus === NetworkStatus.loading ||
    latestUpdateNetworkStatus === NetworkStatus.setVariables ||
    documentRecordNetworkStatus === NetworkStatus.loading ||
    documentRecordNetworkStatus === NetworkStatus.setVariables
  ) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'add-update' && (
        <AddUpdate onClose={closeModalWindow} isOpen={modalWindow.isOpen} manager={managerId} openEditManagerUpdatePage={openEditManagerUpdatePage} />
      )}
      {modalWindow.isOpen && modalWindow.type === 'delete-update' && (
        <DeleteUpdate
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentRow={currentRow}
          latestUpdateId={latestUpdateId}
          latestUpdateRefetch={latestUpdateRefetch}
          listDocumentRecordsRefetch={listDocumentRecordsRefetch}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'change-owner' && (
        <ChangeOwner
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentRow={currentRow}
          listDocumentRecordsRefetch={listDocumentRecordsRefetch}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'download-pdf' && (
        <DownloadPDF
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          canUserEdit={canUserEdit}
          documentRecord={documentRecordData?.getDocumentRecord ?? null}
          updateId={currentRow?.id ?? null}
        />
      )}
      {showZeroState ? (
        <NoUpdates>
          <div>No manager updates have been logged for this investment.</div>
          {canUserEdit && (
            <Button size="md" onClick={() => openModalWindow('add-update')} icon={<AddButtonIcon />}>
              Add New Update
            </Button>
          )}
        </NoUpdates>
      ) : (
        <>
          {canUserEdit && (
            <StatusWrapper isMobile={isMobile}>
              {documentRecordData?.getDocumentRecord && (
                <Status isMobile={isMobile}>
                  <div>
                    {isMoreThan90DaysInPast(documentRecordData.getDocumentRecord.statusUpdatedAt) ? (
                      <OverdueIcon style={{ position: 'relative', top: '2px' }} />
                    ) : (
                      <ApprovedIcon width={27} height={27} style={{ position: 'relative', top: '3px' }} />
                    )}
                  </div>
                  <SeparateStick />
                  <StatusText
                    isMoreThan90DaysInPast={isMoreThan90DaysInPast(documentRecordData.getDocumentRecord.statusUpdatedAt)}
                  >{`Latest Update Published By ${documentRecordData.getDocumentRecord?.publisher?.firstName ?? ''} ${
                    documentRecordData.getDocumentRecord?.publisher?.lastName ?? ''
                  } on ${dateFormatter(
                    format(new Date(documentRecordData.getDocumentRecord.statusUpdatedAt), 'yyyy-MM-dd'),
                    'yyyy-MM-dd'
                  )}`}</StatusText>
                </Status>
              )}
              <AddNewUpdateButton size="md" onClick={() => openModalWindow('add-update')} icon={<AddButtonIcon />}>
                Add New Update
              </AddNewUpdateButton>
            </StatusWrapper>
          )}
          <SectionsWrapper canUserEdit={canUserEdit}>
            <LatestUpdateSection
              documentRecordData={documentRecordData?.getDocumentRecord ?? null}
              openModalWindow={openModalWindow}
              openEditManagerUpdatePage={openEditManagerUpdatePage}
            />
            <AllUpdatesSection
              updatesTableData={updatesTableData}
              sort={{ key, asc }}
              setSort={onChangeSort}
              loading={listDocumentRecordsNetworkStatus === NetworkStatus.loading || listDocumentRecordsNetworkStatus === NetworkStatus.setVariables}
              paginationValues={{ limit: Number(limit), offset: Number(offset), total: listDocumentRecordsData?.listDocumentRecords?.total ?? 0 }}
              onChangePaginationData={onChangePaginationData}
              listDocumentRecordsRefetch={listDocumentRecordsRefetch}
              primaryFilter={primaryFilter}
              onChangePrimaryFilter={onChangePrimaryFilter}
              dropdownActions={dropdownActions}
              openEditManagerUpdatePage={openEditManagerUpdatePage}
              canUserEdit={canUserEdit}
            />
          </SectionsWrapper>
        </>
      )}
    </>
  );
};

const LoaderWrapper = styled.div`
  margin-top: 50px;
`;

const StatusWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: 0 20px;
  margin: 30px 0;
`;

const Status = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'space-between')};
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  gap: ${({ isMobile }) => (isMobile ? '5px' : '20px')};
`;

const SeparateStick = styled.div`
  width: 1px;
  height: 33px;
  background-color: ${({ theme }) => theme.border.base};
`;

const StatusText = styled.span<{ bold?: boolean; isMoreThan90DaysInPast?: boolean }>`
  font-weight: ${({ bold }) => (bold ? '700' : '600')};
  font-size: 16px;
  line-height: 22px;
  color: ${({ isMoreThan90DaysInPast, theme }) => (isMoreThan90DaysInPast ? theme.context.error : theme.font.base)};
`;

const SectionsWrapper = styled.div<{ canUserEdit: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: ${({ canUserEdit }) => (canUserEdit ? '0' : '50px')};
`;

const AddNewUpdateButton = styled(Button)`
  margin-left: auto;
`;

const NoUpdates = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  gap: 40px;
  margin-top: 40px;
`;
