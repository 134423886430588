import { ITab, MainTabsMobile, TabsDetailsTitleMobile, TabsWrapMobile } from '@/components/basicComponents/fat-tabs';
import { IFilterBarData } from '@/components/basicComponents/filterBar';
import { OfferingMaterials } from '@/components/fat-dealCatalog-page/fat-opportunityDetails-page/offeringMaterials';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import { ALL_ADVISORY_FIRM_LIST_QUERY } from '@/components/fat-dealPipeline-page/fat-investorInterest/queries';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { ITenant } from '@/components/fat-investors-page/types';
import { IMobileSort } from '@/components/fat-pendingInvestments-page/types';
import { Loader } from '@/components/loaders/loader';
import { MANAGER_UPDATES_EDITABLE_ROLES } from '@/components/managerUpdates-page/constatnts';
import { LIST_DOCUMENT_RECORDS_QUERY } from '@/components/managerUpdates-page/managerUpdateDetails/queries';
import { IListDocumentRecordsQuery } from '@/components/managerUpdates-page/managerUpdateDetails/types';
import { UpdatesTab } from '@/components/managerUpdates-page/managerUpdateDetails/updatesTab';
import { initialValuesFromTable } from '@/components/table/constants';
import FilterPage from '@/components/table/fat-filterPage';
import TablePagination from '@/components/table/tablePagination';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap } from '@/styles/common';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  COMMITMENTS_BY_INVESTOR_QUERY,
  GET_COMMITMENTS_BY_INVESTOR_TOTAL_QUERY,
  GET_INVESTMENT_ENTITIES_BY_ADVISORY_FIRM_TOTAL_QUERY,
  GET_INVESTMENT_ENTITIES_TOTAL_QUERY,
  INVESTMENT_ENTITIES_BY_ADVISORY_FIRM_QUERY,
  INVESTMENT_ENTITIES_QUERY,
  INVESTMENT_QUERY
} from '../queries';
import { advisoryFirmTableSort, entityTableSort, investorTableSort } from './constatnts';
import { Details } from './details';
import Table from './fat-table/table';
import {
  ICommitmentsByInvestor,
  IInvestorCommitment,
  InvestmentEntitiesByAdvisoryFirmDto,
  InvestmentEntity,
  InvestmentEntityByAdvisoryFirm,
  InvestmentEntityQuery
} from './types';

export const InvestmentDetailsPage = () => {
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const navigate = useNavigate();
  const goBack = useGoBack();
  const location = useLocation();
  const params = useParams();
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';
  const [canUserEdit, setCanUserEdit] = useState(false);
  const activeTab = searchParams.get('tab') || 'Investors';
  const q = searchParams.get('q') || '';
  const viewBy = searchParams.get('viewBy') || '';
  const key = searchParams.get('key') || 'name';
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const primaryFilter = searchParams.get('primaryFilter') || 'All';
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const filterBarParams = searchParams.get('filterBar');
  const position = searchParams.get('position') || 0;

  const [tabs, setTabs] = useState<ITab[]>([]);
  const [isTabMenuOpen, setIsTabMenuOpen] = useState(false);
  const [viewByList, setViewByList] = useState<string[]>([]);
  const [entities, setEntities] = useState<InvestmentEntity[]>([]);
  const [advisoryFirmTableRows, setAdvisoryFirmTableRows] = useState<InvestmentEntityByAdvisoryFirm[]>([]);
  const [investorTableRows, setInvestorTableRows] = useState<IInvestorCommitment[]>([]);
  const [investment, setInvestment] = useState<Investment | null>(null);
  const [primaryFilterList, setPrimaryFilterList] = useState<string[]>([]);
  const [mobileSortItems, setMobileSortItems] = useState<IMobileSort[]>([]);
  const [mobileSort, setMobileSort] = useState('');
  const sortItem = mobileSortItems.find((item) => item.title === mobileSort);
  const mobileSortList = mobileSortItems.map((item) => item.title);
  const [filterBar, setFilterBar] = useState<IFilterBarData[]>([]);
  const [allFiltersSelected, setAllFiltersSelected] = useState(true);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [listDocumentRecordsStatusFilter, setListDocumentRecordsStatusFilter] = useState<string[]>([]);

  useEffect(() => {
    if (MANAGER_UPDATES_EDITABLE_ROLES.some((role) => user.roles.includes(role)) && user.tenant.type === 'fundManager') {
      setListDocumentRecordsStatusFilter(['Draft', 'Published']);
      setCanUserEdit(true);
      return;
    }
    setListDocumentRecordsStatusFilter(['Published']);
  }, [user]);

  useEffect(() => {
    if (!user) return;

    if (user.tenant.type === 'advisoryFirm') {
      setViewByList(['Investor', 'Entity']);
      if (!viewBy) {
        onChangeViewBy('Investor');
      }
      return;
    }

    if (user.tenant.type === 'fundManager') {
      setViewByList(['Advisory Firm', 'Investor', 'Entity']);
      if (!viewBy) {
        onChangeViewBy('Advisory Firm');
      }
      return;
    }
  }, [user]);

  const filteredMobileSortItems = (items: IMobileSort[]) => {
    if (user && user.tenant.type === 'advisoryFirm') {
      return items.filter((item) => item.key !== 'ADVISORY_FIRMS');
    }
    return items;
  };

  useEffect(() => {
    if (!isMobile) return;

    switch (viewBy) {
      case 'Advisory Firm':
        const filteredAdvisoryFirmTableSort = filteredMobileSortItems(advisoryFirmTableSort);
        setMobileSortItems(filteredAdvisoryFirmTableSort);
        setMobileSort(filteredAdvisoryFirmTableSort[0].title);
        break;
      case 'Investor':
        const filteredInvestorTableSort = filteredMobileSortItems(investorTableSort);
        setMobileSortItems(filteredInvestorTableSort);
        setMobileSort(filteredInvestorTableSort[0].title);
        break;
      case 'Entity':
        const filteredEntityTableSort = filteredMobileSortItems(entityTableSort);
        setMobileSortItems(filteredEntityTableSort);
        setMobileSort(filteredEntityTableSort[0].title);
        break;
      default:
        break;
    }
  }, [viewBy, isMobile]);

  useEffect(() => {
    if (!isMobile) return;
    getCurrentStatus().refetch({
      order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: sortItem?.key
    });
  }, [mobileSort]);

  const { data: listDocumentRecordsData, loading: listDocumentRecordsLoading } = useQuery<{ listDocumentRecords: IListDocumentRecordsQuery }>(
    LIST_DOCUMENT_RECORDS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      skip: !params?.strategyId || !listDocumentRecordsStatusFilter.length,
      variables: {
        data: {
          investmentId: params?.strategyId,
          sortBy: 'NAME',
          sortDirection: SORT_ORDER.ASC,
          statusFilter: listDocumentRecordsStatusFilter,
          limit: 1
        }
      },
      onError: (error) => {
        console.error(error);
      }
    }
  );

  const { data: advisoryFirmsTotalData } = useQuery<{
    InvestmentEntitiesByAdvisoryFirm: InvestmentEntitiesByAdvisoryFirmDto;
  }>(GET_INVESTMENT_ENTITIES_BY_ADVISORY_FIRM_TOTAL_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.strategyId,
    variables: {
      id: params?.strategyId
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: investorsTotalData } = useQuery<{ CommitmentsByInvestor: ICommitmentsByInvestor }>(GET_COMMITMENTS_BY_INVESTOR_TOTAL_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.strategyId,
    variables: {
      investmentId: params?.strategyId
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: entitiesTotalData } = useQuery<{ InvestmentEntities: InvestmentEntityQuery }>(GET_INVESTMENT_ENTITIES_TOTAL_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.strategyId,
    variables: {
      id: params?.strategyId
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: listAdvisors } = useQuery<{ allAdvisoryFirmList: ITenant[] }>(ALL_ADVISORY_FIRM_LIST_QUERY, {
    skip: Boolean(user && user.tenant.type !== 'fundManager'),
    onCompleted: ({ allAdvisoryFirmList }) => {
      setPrimaryFilterList(['All', ...new Set(allAdvisoryFirmList.map((el) => el.name))]);
    }
  });

  const {
    data: investmentData,
    loading: loadingInvestment,
    error: errorInvestment
  } = useQuery<{ Investment: Investment }>(INVESTMENT_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: { id: params?.strategyId },
    skip: !params?.strategyId,
    onCompleted: (data) => {
      setInvestment(data.Investment);
    }
  });

  const {
    data: entitiesData,
    loading: entitiesLoading,
    refetch: entitiesRefetch
  } = useQuery<{ InvestmentEntities: InvestmentEntityQuery }>(INVESTMENT_ENTITIES_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.strategyId || viewBy !== 'Entity',
    variables: {
      id: params?.strategyId,
      sort: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      search: q.toLowerCase().trim(),
      rowsPerPage: Number(limit),
      page: Math.floor(Number(offset) / Number(limit)) + 1,
      quickFilter: primaryFilter === 'All' ? null : primaryFilter,
      vehicleFilter: allFiltersSelected ? null : filterBar.filter((item) => item.enabled).map((item) => item.value)
    },
    onCompleted: (data) => {
      setEntities(data.InvestmentEntities.entities);
    }
  });

  const {
    data: advisoryFirmsData,
    loading: advisoryFirmsLoading,
    refetch: advisoryFirmsRefetch
  } = useQuery<{
    InvestmentEntitiesByAdvisoryFirm: InvestmentEntitiesByAdvisoryFirmDto;
  }>(INVESTMENT_ENTITIES_BY_ADVISORY_FIRM_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.strategyId || viewBy !== 'Advisory Firm',
    variables: {
      id: params?.strategyId,
      sort: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      search: q.toLowerCase().trim(),
      rowsPerPage: Number(limit),
      page: Math.floor(Number(offset) / Number(limit)) + 1,
      quickFilter: primaryFilter === 'All' ? null : primaryFilter
      // vehicleFilter: allFiltersSelected ? null : filterBar.filter((item) => item.enabled).map((item) => item.value),
    },
    onCompleted: (data) => {
      setAdvisoryFirmTableRows(data.InvestmentEntitiesByAdvisoryFirm.firms);
      setFilterBarItems(data.InvestmentEntitiesByAdvisoryFirm.filters || []);
    }
  });

  const {
    data: investorsData,
    loading: investorsLoading,
    refetch: investorsRefetch
  } = useQuery<{
    CommitmentsByInvestor: ICommitmentsByInvestor;
  }>(COMMITMENTS_BY_INVESTOR_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.strategyId || viewBy !== 'Investor',
    variables: {
      investmentId: params?.strategyId,
      sort: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      search: q.toLowerCase().trim(),
      rowsPerPage: Number(limit),
      page: Math.floor(Number(offset) / Number(limit)) + 1,
      quickFilter: primaryFilter === 'All' ? null : primaryFilter
      // vehicleFilter: allFiltersSelected ? null : filterBar.filter((item) => item.enabled).map((item) => item.value),
    },
    onCompleted: (data) => {
      setInvestorTableRows(data.CommitmentsByInvestor.investors);
      setFilterBarItems(data.CommitmentsByInvestor.filters || []);
    }
  });

  useEffect(() => {
    const newTabs: ITab[] = [];

    if (
      advisoryFirmsTotalData?.InvestmentEntitiesByAdvisoryFirm.total ||
      investorsTotalData?.CommitmentsByInvestor.total ||
      entitiesTotalData?.InvestmentEntities.total
    ) {
      newTabs.push({ value: 'Investors', amount: null });
    }

    if (listDocumentRecordsData?.listDocumentRecords?.total || location.pathname.includes('/managerUpdates')) {
      newTabs.push({ value: 'Updates', amount: null });
    }

    if (investmentData?.Investment?.offeringMaterialsAttachments?.length || investmentData?.Investment?.offeringMaterialsDescription) {
      newTabs.push({ value: 'Offering Materials', amount: null });
    }
    setTabs(newTabs);
  }, [investmentData, advisoryFirmsTotalData, investorsTotalData, entitiesTotalData, listDocumentRecordsData]);

  useEffect(() => {
    if (entitiesLoading || advisoryFirmsLoading || investorsLoading || loadingInvestment || listDocumentRecordsLoading) return;
    makeScroll(position);
  }, [entitiesLoading, advisoryFirmsLoading, investorsLoading, loadingInvestment, listDocumentRecordsLoading]);

  const getCurrentStatus = () => {
    switch (viewBy) {
      case 'Advisory Firm':
        return {
          capitalCalled: advisoryFirmTableRows.reduce((sum, val) => sum + Number(val.capitalCalled), 0),
          refetch: advisoryFirmsRefetch,
          total: advisoryFirmsData?.InvestmentEntitiesByAdvisoryFirm?.total ?? 0
        };
      case 'Investor':
        return {
          capitalCalled: investorTableRows.reduce((sum, val) => sum + Number(val.capitalCalled), 0),
          refetch: investorsRefetch,
          total: investorsData?.CommitmentsByInvestor?.total ?? 0
        };
      case 'Entity':
        return {
          capitalCalled: entities.reduce((sum, val) => sum + Number(val.capitalCalled), 0),
          refetch: entitiesRefetch,
          total: entitiesData?.InvestmentEntities?.total ?? 0
        };
      default:
        return {
          capitalCalled: 0,
          refetch: () => null as any,
          total: 0
        };
    }
  };

  const openModifyInvestment = (id?: string) => {
    navigate(`/dealPipeline/modify?backToTitle=Investment Details&investment=${encodeURIComponent(id)}`, {
      state: {
        investment: id
      }
    });
  };

  const setFilterBarItems = (filters: IFilterBarData[]) => {
    setIsFirstLoading(false);

    if (filters.length === 1) {
      setFilterBar([]);
      return;
    }

    const parsedFilterBarParams = JSON.parse(filterBarParams || '[]');

    if (!parsedFilterBarParams.length && isFirstLoading) {
      const updatedFilters = filters.map((filterItem) => {
        return {
          ...filterItem,
          enabled: true
        };
      });
      setFilterBar(updatedFilters);
      return;
    }

    if (!parsedFilterBarParams.length) {
      setFilterBar(filters);
      return;
    }

    const filterBarItems = filters.map((filterItem) => {
      const matchedFilter = parsedFilterBarParams.find((item: string) => item === filterItem.value);
      if (matchedFilter) {
        return {
          ...filterItem,
          enabled: true
        };
      }
      return {
        ...filterItem,
        enabled: false
      };
    });

    setFilterBar(filterBarItems);
  };

  useEffect(() => {
    if (filterBar.length) {
      const allFilters = filterBar.every((item) => item.enabled);
      setAllFiltersSelected(allFilters);
    }
  }, [filterBar]);

  const changeTab = (value: string) => {
    onChangeActiveTab(value);
    setIsTabMenuOpen(false);
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeViewBy = (viewByValue: string) => {
    setSearchParams(
      (prev) => {
        prev.set('viewBy', viewByValue);
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePrimaryFilter = (filter: string) => {
    setSearchParams(
      (prev) => {
        prev.set('primaryFilter', filter);
        return prev;
      },
      { replace: true }
    );
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeActiveTab = (value: string) => {
    setSearchParams({ viewBy, tab: value }, { replace: true });
  };

  const onChangeFilterBar = (filterBarItems: IFilterBarData[]) => {
    setFilterBar(filterBarItems);
    const filterBarValues = filterBarItems.filter((item) => item.enabled).map((item) => item.value);
    setSearchParams(
      (prev) => {
        if (!filterBarValues.length) {
          prev.delete('filterBar');
          return prev;
        }
        prev.set('filterBar', JSON.stringify(filterBarValues));
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const openDetailsPage = (investorId: string, entityId?: string) => {
    saveScrollPostion();
    if (investorId && entityId) {
      navigate(`/investors/${investorId}/${entityId}`);
      return;
    }
    navigate(`/investors/${investorId}`);
  };

  const openAdvisorFirmDetails = (id: string) => {
    onChangeViewBy('Investor');
    const selectedAdvisor = listAdvisors.allAdvisoryFirmList.find((item) => item.id === id)?.name || 'All';
    onChangePrimaryFilter(selectedAdvisor);
  };

  if (loadingInvestment) {
    return <CustomLoader size={60} />;
  }

  return (
    <>
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/activeInvestments' })} backToTitle={backToTitle} />} />
        <PageTitle
          title={investment?.name ?? ''}
          {...(user &&
            user.tenant.type === 'fundManager' && {
              onEditClick: () => openModifyInvestment(params?.strategyId as string)
            })}
        />
        <Details
          data={investment}
          capitalCalled={getCurrentStatus().capitalCalled}
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={onChangeActiveTab}
          isTabMenuOpen={isTabMenuOpen}
          setIsTabMenuOpen={setIsTabMenuOpen}
        />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          {isTabMenuOpen && isMobile && (
            <MainTabsMobile>
              {tabs.map((tab, tabId) => (
                <TabsWrapMobile key={tab.value + tabId} onClick={() => changeTab(tab.value)} isActive={activeTab === tab.value}>
                  <TabsDetailsTitleMobile isActive={activeTab === tab.value}>{tab.value}</TabsDetailsTitleMobile>
                </TabsWrapMobile>
              ))}
            </MainTabsMobile>
          )}
          {!isTabMenuOpen && (
            <>
              {activeTab === 'Investors' && (
                <>
                  <CustomFilterPage
                    isMobile={isMobile}
                    isTablet={isTablet}
                    search={{
                      value: q,
                      onChange: onChangeSearch,
                      placeholder: 'Search for an investor'
                    }}
                    {...(user &&
                      user.tenant.type === 'fundManager' && {
                        primaryFilter: {
                          value: primaryFilter,
                          onChange: onChangePrimaryFilter,
                          selects: primaryFilterList
                        }
                      })}
                    resultsValue={getCurrentStatus().total}
                    refetch={getCurrentStatus().refetch}
                    viewBySelect={{
                      value: viewBy,
                      onChange: onChangeViewBy,
                      selects: viewByList
                    }}
                    mobileSort={{
                      value: mobileSort,
                      onChange: setMobileSort,
                      selects: mobileSortList
                    }}
                    {...(filterBar.length &&
                      viewBy === 'Entity' && {
                        filterBar,
                        onChangeFilterBar
                      })}
                  />
                  <Table
                    advisoryFirmTableRows={advisoryFirmTableRows}
                    entityTableRows={entities}
                    investorTableRows={investorTableRows}
                    refetch={getCurrentStatus().refetch}
                    loading={entitiesLoading || advisoryFirmsLoading || investorsLoading}
                    viewByValue={viewBy}
                    sort={{ key, asc }}
                    setSort={onChangeSort}
                    openDetailsPage={openDetailsPage}
                    openAdvisorFirmDetails={openAdvisorFirmDetails}
                    withFilterBar={!!filterBar.length}
                  />
                  <PaginationWrap>
                    <TablePagination
                      savePagination={onChangePaginationData}
                      paginationValues={{
                        limit: Number(limit),
                        offset: Number(offset),
                        total: getCurrentStatus().total
                      }}
                      refetch={() => null as any}
                    />
                  </PaginationWrap>
                </>
              )}
              {activeTab === 'Updates' && <UpdatesTab managerId={investmentData?.Investment?.managerId || null} backToTitle="Strategy Details" />}
              {activeTab === 'Offering Materials' && (
                <OfferingMaterialsWrap>
                  <OfferingMaterials
                    attachments={investment?.offeringMaterialsAttachments ?? []}
                    description={investment?.offeringMaterialsDescription ?? ''}
                  />
                </OfferingMaterialsWrap>
              )}
            </>
          )}
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const CustomLoader = styled(Loader)`
  height: 100%;
  align-items: center;
`;

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const OfferingMaterialsWrap = styled.div`
  margin: 20px 0;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
