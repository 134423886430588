import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import Button from '@/components/fat-basicComponents/button';
import { Loader } from '@/components/loaders/loader';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { DefaultTheme } from 'styled-components/dist/types';
import { deleteCommitmentPlanMutation } from '../../queries';
import { AUTO_PLAN_STATUS_ENUM, ICommitmentPlan } from '../types';

const getCommitmentPlanStatus = (status: AUTO_PLAN_STATUS_ENUM, theme: DefaultTheme) => {
  switch (status) {
    case AUTO_PLAN_STATUS_ENUM.IN_PROGRESS:
      return {
        icon: <Loader size={60} />,
        title: 'Generating Commitment Plan',
        description:
          'This process may require a few minutes.  Feel free to leave this screen and return later.  Once your plan is complete, you have the option to manually adjust annual commitment amounts before saving your plan and viewing forecasted allocations and cashflows.'
      };
    case AUTO_PLAN_STATUS_ENUM.WAITING_FOR_REVIEW:
      return {
        icon: <ApprovedIcon width={60} height={60} fill={theme.context.success} />,
        title: 'Commitment Plan Ready for Review',
        description:
          'Your commitment plan is ready for your review.  Make changes to annual commitment amounts or, if everything looks good, save it as is to view forecasted allocations and cashflows.'
      };
    case AUTO_PLAN_STATUS_ENUM.FAILED:
      return {
        icon: <InfoIcon width={60} height={60} style={{ transform: 'rotate(180deg)' }} fill={theme.context.error} />,
        title: 'Auto Plan Error',
        description:
          'We were unable to construct a commitment plan that achieves your goals.  Try increasing the number of years allowed to achieve the allocation targets.  Or, if you prefer, you can manually enter annual commitment amounts.'
      };
    default:
      return {
        icon: <></>,
        title: '',
        description: ''
      };
  }
};

interface CommitmentPlanStatusesProps {
  status: AUTO_PLAN_STATUS_ENUM;
  currentCommitmentPlan: ICommitmentPlan | null;
  commitmentPlansRefetch: () => void;
}

const CommitmentPlanStatuses = ({ status, currentCommitmentPlan, commitmentPlansRefetch }: CommitmentPlanStatusesProps) => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();

  const [deleteCommitmentPlan, { loading: deleteCommitmentPlanLoading }] = useMutation(deleteCommitmentPlanMutation, {
    onError: (error) => {
      console.error(error);
    }
  });

  const startOver = () => {
    deleteCommitmentPlan({
      variables: {
        id: currentCommitmentPlan.id
      },
      onCompleted: () => {
        commitmentPlansRefetch();
      }
    });
  };

  const openEditCommitmentPlanPage = () => {
    if (!currentCommitmentPlan) return;

    navigate(
      `/investors/${params.investorId}/${params.entityId}/editCommitmentPlan?commitmentPlanId=${currentCommitmentPlan.id}&backToTitle=Investor Entity`
    );
  };

  return (
    <CommitmentPlanStatusWrapper>
      <CommitmentPlanStatus>
        {getCommitmentPlanStatus(status, theme).icon}
        <div>
          <h2>{getCommitmentPlanStatus(status, theme).title}</h2>
          <p>{getCommitmentPlanStatus(status, theme).description}</p>
        </div>
      </CommitmentPlanStatus>
      {(status === AUTO_PLAN_STATUS_ENUM.IN_PROGRESS || status === AUTO_PLAN_STATUS_ENUM.WAITING_FOR_REVIEW) && (
        <Button
          onClick={openEditCommitmentPlanPage}
          size="md"
          styleType={status === AUTO_PLAN_STATUS_ENUM.WAITING_FOR_REVIEW && currentCommitmentPlan ? 'default' : 'disabled'}
        >
          Edit Commitment Plan
        </Button>
      )}
      {status === AUTO_PLAN_STATUS_ENUM.FAILED && (
        <ButtonsWrapper>
          <Button onClick={startOver} size="md" isLoading={deleteCommitmentPlanLoading}>
            Start Over
          </Button>
          <Button onClick={openEditCommitmentPlanPage} size="md" styleType="outline">
            Create Plan Manually
          </Button>
        </ButtonsWrapper>
      )}
    </CommitmentPlanStatusWrapper>
  );
};

export default CommitmentPlanStatuses;

const CommitmentPlanStatusWrapper = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
`;

const CommitmentPlanStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: ${({ theme }) => theme.font.weak};
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 20px;
  padding: 20px 80px;
  font-family: Lato;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
