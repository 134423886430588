import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import ModalLoader from '@/components/fat-basicComponents/modal/modalLoader';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { updateDocumentRecordStatusMutation } from '../queries';

interface UnpublishUpdateProps {
  onClose: () => void;
  isOpen: boolean;
  updateId: string | null;
  refetch: () => void;
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

const UnpublishUpdate = ({ onClose, isOpen, updateId, refetch }: UnpublishUpdateProps) => {
  const theme = useTheme();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [updateDocumentRecordStatus, { loading: updateDocumentRecordStatusLoading }] = useMutation(updateDocumentRecordStatusMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  });

  const saveValue = () => {
    setErrorMessage(null);

    if (!updateId) {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      return;
    }

    updateDocumentRecordStatus({
      variables: {
        data: {
          id: updateId,
          status: 'Draft'
        }
      },
      onCompleted: () => {
        refetch();
        onClose();
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'Unpublish', onClick: saveValue, disabled: updateDocumentRecordStatusLoading }}
      isUseOutsideClick={!updateDocumentRecordStatusLoading}
      showCloseIcon={!updateDocumentRecordStatusLoading}
      showCancelButton={!updateDocumentRecordStatusLoading}
    >
      <ModalBody>
        <ModalTitle>Unpublish Update</ModalTitle>
        {updateDocumentRecordStatusLoading ? (
          <ModalLoader />
        ) : (
          <>
            {errorMessage && (
              <ErrorBannerWrapper>
                <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={errorMessage} bgColor={theme.context.error} />
              </ErrorBannerWrapper>
            )}
            <ModalDescription>This update will be available to edit and publish at a later date.</ModalDescription>
          </>
        )}
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default UnpublishUpdate;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 35px 0;
`;

const ModalTitle = styled.div`
  text-align: center;
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
  color: ${({ theme }) => theme.font.base};
`;

const ModalDescription = styled.div`
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  line-height: 26.6px;
  color: ${({ theme }) => theme.font.base};
`;

const ErrorBannerWrapper = styled.div`
  margin-bottom: 10px;
`;
