import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import Label from '@/components/basicComponents/label';
import FilterSelect from '@/components/basicComponents/select';
import Textarea from '@/components/basicComponents/textarea';
import { LIST_USERS_QUERY } from '@/components/dueDiligence-page/queries';
import ModalLoader from '@/components/fat-basicComponents/modal/modalLoader';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { User } from '@/types/user';
import { updateDocumentRecordMutation, updateDocumentRecordStatusMutation } from '../queries';
import { useAuth } from '@/hooks/use-auth';

interface ChangeAssigneeProps {
  onClose: () => void;
  isOpen: boolean;
  updateId: string | null;
  assignee: User | null;
  owner: User | null;
  status: string | null;
  documentRecordRefetch: () => void;
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

const ChangeAssignee = ({ isOpen, onClose, updateId, assignee, owner, status, documentRecordRefetch }: ChangeAssigneeProps) => {
  const theme = useTheme();

  const [selectedAssignee, setSelectedAssignee] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [noteValue, setNoteValue] = useState('');
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!assignee || !owner) {
      setSelectedAssignee('Select');
      return;
    }
    setSelectedAssignee(assignee ? `${assignee.firstName} ${assignee.lastName}` : `${owner?.firstName} ${owner?.lastName}`);
  }, [assignee, owner]);

  const [updateDocumentRecord, { loading: updateDocumentRecordLoading }] = useMutation(updateDocumentRecordMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  });

  const [updateDocumentRecordNote, { loading: updateDocumentRecordStatusLoading }] = useMutation(updateDocumentRecordStatusMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  });

  const { data: listUsersData, loading: listUsersLoading } = useQuery(LIST_USERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        roles: ['INVESTMENT_LEAD', 'INVESTMENT_MEMBER'],
        tenantId: user.tenant.id
      }
    },
    onCompleted: (data) => {
      if (!data?.listUsers?.length) return;
      setUsersList(data.listUsers.map((user: User) => `${user.firstName} ${user.lastName}`));
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const changeAssignee = () => {
    setErrorMessage(null);
    const selectedAssigneeObject = listUsersData?.listUsers?.find((item: User) => `${item.firstName} ${item.lastName}` === selectedAssignee);

    if (!selectedAssigneeObject || !updateId || !status) {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      return;
    }

    updateDocumentRecord({
      variables: {
        data: {
          id: updateId,
          assigneeEmail: selectedAssigneeObject.email
        }
      },
      onCompleted: () => {
        updateDocumentRecordNote({
          variables: {
            data: {
              id: updateId,
              status: status,
              note: noteValue
            }
          },
          onCompleted: () => {
            documentRecordRefetch();
            onClose();
          }
        });
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Change Assignee'}
      confirmButton={{
        name: 'Assign',
        onClick: changeAssignee,
        disabled: listUsersLoading || updateDocumentRecordLoading || updateDocumentRecordStatusLoading || selectedAssignee === 'Select'
      }}
      showRequiredFields={!updateDocumentRecordLoading || !updateDocumentRecordStatusLoading}
      showCloseIcon={!updateDocumentRecordLoading || !updateDocumentRecordStatusLoading}
      showCancelButton={!updateDocumentRecordLoading || !updateDocumentRecordStatusLoading}
      isUseOutsideClick={!updateDocumentRecordLoading || !updateDocumentRecordStatusLoading}
    >
      {updateDocumentRecordLoading || updateDocumentRecordStatusLoading ? (
        <ModalLoader />
      ) : (
        <>
          {errorMessage && (
            <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={errorMessage} bgColor={theme.context.error} />
          )}
          <ChangeAssigneeWrapper>
            <AssigneeSelect
              width={'auto'}
              label={'Select An Assignee'}
              selected={selectedAssignee}
              setSelected={(value) => setSelectedAssignee(value)}
              data={usersList}
              required
            />
            <div>
              <Label required={false} fontSize="16px">
                Notes
              </Label>
              <Textarea maxLength={250} value={noteValue} setValue={setNoteValue} />
            </div>
          </ChangeAssigneeWrapper>
        </>
      )}
    </ModalWrappedContent>
  );
};

export default ChangeAssignee;

const ChangeAssigneeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const AssigneeSelect = styled(FilterSelect)`
  border-radius: 5px;
  padding: 10px 12px;

  #titleSelect {
    font-family: Blinker;
    color: ${({ theme }) => theme.font.strong};
    font-size: 19px;
    font-weight: 400;
    line-height: 26.6px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;
