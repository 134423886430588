import NoResults from '@/components/table/noResults';
import { AssetClassTargetAllocationDto } from '../types';
import TableRow from './tableRow';

interface TableBodyProps {
  investorSummaryRows: AssetClassTargetAllocationDto[];
  ignoreAllocationTargets: boolean;
  targetSubAssetClasses: boolean;
  commitmentPlansTotal: number | null;
}

const TableBody = ({ investorSummaryRows, ignoreAllocationTargets, targetSubAssetClasses, commitmentPlansTotal }: TableBodyProps) => {
  if (!investorSummaryRows?.length) {
    return <NoResults />;
  }

  return (
    <>
      {investorSummaryRows.map((row) => (
        <TableRow
          key={row.id}
          row={row}
          ignoreAllocationTargets={ignoreAllocationTargets}
          targetSubAssetClasses={targetSubAssetClasses}
          commitmentPlansTotal={commitmentPlansTotal}
        />
      ))}
    </>
  );
};

export default TableBody;
