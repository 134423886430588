import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';

import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { formatterClient } from '@/components/clients-page/utils';
import { TableBodyWrapper, TableRowCell, TextCell } from '@/components/table/styles';
import { formatCash } from '@/utils/formatCash';
import styled from 'styled-components';
import { summaryTablecolumns } from '../../constatnts';
import { AssetClassTargetAllocationDto, IAllocationPacingTarget } from '../../types';
import MobileSumFooter from './mobileSumFooter';
import { getUTCDate } from '@/components/allocations-page/utils';

interface MobileTableProps {
  investorSummaryRows: AssetClassTargetAllocationDto[] | undefined;
  loading: boolean;
  ignoreAllocationTargets: boolean;
  commitmentPlansTotal: number | null;
}

const pacingTargetToolTipContent = (row: IAllocationPacingTarget) => {
  return (
    <TableBodyWrapper>
      <TableRowCell width={100}>
        <TextCell>Plan Start {getUTCDate(new Date(row.startDate)).toUpperCase()}</TextCell>
      </TableRowCell>
      <TableRowCell width={100}>
        <TextCell>Cumulative Plan Goal Since Start: {formatterClient.format(row.cumulativePacingTarget)}</TextCell>
      </TableRowCell>
      <TableRowCell width={100}>
        <TextCell>Commitments Since Start: {formatterClient.format(row.cumulativeCommitments)}</TextCell>
      </TableRowCell>
      <TableRowCell width={100}>
        <TextCell>
          Remaining Pacing Target Through {getUTCDate(new Date(row.pacingTargetThroughDate)).toUpperCase()}:{' '}
          {formatterClient.format(row.remainingPacingTarget)}
        </TextCell>
      </TableRowCell>
    </TableBodyWrapper>
  );
};

const MobileTable = ({ investorSummaryRows, loading, ignoreAllocationTargets, commitmentPlansTotal }: MobileTableProps) => {
  const TableRow = ({ el }: { el: AssetClassTargetAllocationDto }) => {
    return (
      <MobileTableRowWrap key={el.id}>
        <MobileRow>
          <MobileRowTitle>{summaryTablecolumns[0].title}</MobileRowTitle>
          <MobileRowValue>{el.name}</MobileRowValue>
        </MobileRow>
        {!ignoreAllocationTargets && (
          <MobileRow>
            <MobileRowTitle>{summaryTablecolumns[1].title}</MobileRowTitle>
            <MobileRowValue>{el.percentage === null ? '--' : `${el.percentage.toFixed(0)}%`}</MobileRowValue>
          </MobileRow>
        )}
        {!ignoreAllocationTargets && (
          <MobileRow>
            <MobileRowTitle>{summaryTablecolumns[2].title}</MobileRowTitle>
            <MobileRowValue>
              <FormattingTooltip zIndex={1000}>{el.targetAllocation}</FormattingTooltip>
            </MobileRowValue>
          </MobileRow>
        )}

        <MobileRow>
          <MobileRowTitle>{summaryTablecolumns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.economicExposure}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        {!ignoreAllocationTargets && (
          <MobileRow>
            <MobileRowTitle>{summaryTablecolumns[4].title}</MobileRowTitle>
            <MobileRowValue>
              <FormattingTooltip zIndex={1000}>{el.capacity}</FormattingTooltip>
            </MobileRowValue>
          </MobileRow>
        )}

        {!ignoreAllocationTargets && commitmentPlansTotal !== null && commitmentPlansTotal > 0 && (
          <MobileRow>
            <MobileRowTitle>{summaryTablecolumns[5]?.title}</MobileRowTitle>
            <MobileRowValue>
              {el.pacingTarget ? (
                <CellWithTooltip>
                  {formatCash(el.pacingTarget.remainingPacingTarget)}
                  <BasicTooltip tooltipContent={pacingTargetToolTipContent(el.pacingTarget)} withTooltipIcon zIndex={1000} />
                </CellWithTooltip>
              ) : (
                <>--</>
              )}
            </MobileRowValue>
          </MobileRow>
        )}
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {investorSummaryRows?.length ? (
        <>
          {investorSummaryRows.map((el, index) => {
            return <TableRow el={el} key={index} />;
          })}
          <MobileSumFooter investorSummaryRows={investorSummaryRows} loading={loading} ignoreAllocationTargets={ignoreAllocationTargets} />
        </>
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const CellWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
