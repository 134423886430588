import { InvestmentDetailsPage } from '@/components/fat-activeInvestments-page/investmentDetails';
import { AllocatorPage } from '@/components/fat-dealCatalog-page/fat-allocator-page';
import { OpportunityDetailsPage } from '@/components/fat-dealCatalog-page/fat-opportunityDetails-page';
import { SeekingInterestPage } from '@/components/fat-dealCatalog-page/fat-seekingInterest-page';
import { InvestorInterestPage } from '@/components/fat-dealPipeline-page/fat-investorInterest';
import { CreateInvestorPage } from '@/components/fat-investors-page/fat-addInvestors';
import { InvestorDetailsPage } from '@/components/fat-investors-page/fat-investorDetails-page';
import { EditInvestorPage } from '@/components/fat-investors-page/fat-investorDetails-page/fat-editInvestor';
import { EntityDetailsPage } from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page';
import { AllCommitmentPlans } from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/allCommitmentPlans-page';
import AutoPlanWizardPage from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/autoPlanWizardPage';
import { EditCommitmentPlanPage } from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/edit-commitment-plan-page';
import { EditEntityPage } from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/edit-entity';
import { DashboardLayout as DashboardLayoutFat } from '@/layouts/fat-dashboard/dashboard-layout';
import {
  ActiveInvestmentsPage,
  DealCatalogPage,
  DealPipelinePage,
  EditManagerUpdatePage,
  InvestorsPage,
  ManagerUpdatesPage,
  ModifyStrategyPage,
  NotFound,
  PendingInvestmentsPage,
  ProfilePage,
  SettingsPage
} from '@/pages';
import Login from '@/pages/Auth/LoginPage';
import PasswordRecovery from '@/pages/Auth/PasswordRecovery';
import ReportingDataPage from '@/pages/ReportingData';
import { RouteObject } from 'react-router-dom';
import DealRegistryPage from '../pages/DealRegistry';
import DueDiligencePage from '../pages/DueDiligence';
import DueDiligenceRecordPage from '../pages/DueDiligence/Record';
import ModifyDiligencePage from '../pages/DueDiligence/modify';
import { PrivateRoute } from './PrivateRoute';

export const fatRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/password-recovery',
    element: <PasswordRecovery />
  },
  {
    element: (
      <PrivateRoute>
        <DashboardLayoutFat />
      </PrivateRoute>
    ),
    children: [
      {
        path: '/dealCatalog',
        element: <DealCatalogPage />
      },
      {
        path: '/dealCatalog/allocator/:id',
        element: <AllocatorPage />
      },
      {
        path: '/dealCatalog/seekingInterest/:id',
        element: <SeekingInterestPage />
      },
      {
        path: '/dealCatalog/opportunityDetails/:id',
        element: <OpportunityDetailsPage />
      },
      {
        path: '/dealPipeline',
        element: <DealPipelinePage />
      },
      {
        path: '/dealPipeline/modify',
        element: <ModifyStrategyPage />
      },
      {
        path: '/dealPipeline/:id/:name',
        element: <InvestorInterestPage />
      },
      {
        path: '/investors',
        element: <InvestorsPage />
      },
      {
        path: '/investors/create-investor',
        element: <CreateInvestorPage />
      },
      {
        path: '/investors/:investorId',
        element: <InvestorDetailsPage />
      },
      {
        path: '/investors/:investorId/edit',
        element: <EditInvestorPage />
      },
      {
        path: '/investors/:investorId/:entityId',
        element: <EntityDetailsPage />
      },
      {
        path: '/investors/:investorId/:entityId/edit',
        element: <EditEntityPage />
      },
      {
        path: '/investors/:investorId/:entityId/opportunityDetails/:id',
        element: <OpportunityDetailsPage />
      },
      {
        path: '/investors/:investorId/:entityId/editCommitmentPlan',
        element: <EditCommitmentPlanPage />
      },
      {
        path: '/investors/:investorId/:entityId/allCommitmentPlans',
        element: <AllCommitmentPlans />
      },
      {
        path: '/investors/:investorId/:entityId/autoPlan',
        element: <AutoPlanWizardPage />
      },
      {
        path: '/dealRegistry',
        element: <DealRegistryPage />
      },
      {
        path: '/dueDiligence',
        element: <DueDiligencePage />
      },
      {
        path: '/dueDiligence/:recordId',
        element: <DueDiligenceRecordPage />
      },
      {
        path: '/dueDiligence/modify',
        element: <ModifyDiligencePage />
      },
      {
        path: '/managerUpdates',
        element: <ManagerUpdatesPage />
      },
      {
        path: '/managerUpdates/:strategyId',
        element: <InvestmentDetailsPage />
      },
      {
        path: '/managerUpdates/:strategyId/edit/:updateId',
        element: <EditManagerUpdatePage />
      },
      {
        path: '/pendingInvestments',
        element: <PendingInvestmentsPage />
      },
      {
        path: '/pendingInvestments/opportunityDetails/:id',
        element: <OpportunityDetailsPage />
      },
      {
        path: '/activeInvestments',
        element: <ActiveInvestmentsPage />
      },
      {
        path: '/activeInvestments/:strategyId',
        element: <InvestmentDetailsPage />
      },
      {
        path: '/activeInvestments/:strategyId/edit/:updateId',
        element: <EditManagerUpdatePage />
      },
      {
        path: '/reportingData',
        element: <ReportingDataPage />
      },
      {
        path: '/settings',
        element: <SettingsPage />
      },
      {
        path: '/profile',
        element: <ProfilePage />
      }
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
];
