import { RefreshIcon } from '@/assets/icons/info-icons/refresh';
import FilterSelect from '@/components/basicComponents/select';
import SpecialControlHeader from '@/components/fat-dealCatalog-page/fat-switcher/specialControlHeader';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { Loader } from '@/components/loaders/loader';
import MobileModal from '@/components/mobileModal';
import { initialValuesFromTable } from '@/components/table/constants';
import { Results } from '@/components/table/fat-filterPage/desktop-filter';
import { RefreshButton, TopWrapper } from '@/components/table/filterPage/desktop-filter';
import TablePagination from '@/components/table/tablePagination';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap } from '@/styles/common';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { columns, mobileTableSort } from './constatnts';
import Details from './details';
import DeleteEntity from './fat-modals/deleteEntity';
import Table from './fat-table/table';
import { MobileInfoList } from './mobileInfoList';
import { MobileNotes } from './mobileNotes';
import Notes from './notes';
import { GET_INVESTOR_QUERY } from './queries';
import { IInvestorQuery, IInvestorTotalRow, ILegalEntity } from './types';

export const InvestorDetailsPage = () => {
  const { isMobile, isTablet } = useResponsive();
  const goBack = useGoBack();
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';
  const key = searchParams.get('key') || 'entityName';
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const position = searchParams.get('position') || 0;
  const switcher = searchParams.get('switcher') === null || searchParams.get('switcher') === 'true';

  const [isDetailsOpen, setIsDetailsOpen] = useState(true);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [tableRows, setTableRows] = useState<ILegalEntity[]>([]);
  const [isModalOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<ILegalEntity>();
  const [showSpecificColumn, setShowSpecificColumn] = useState(true);
  const [tableColumns, setTableColumns] = useState(columns);
  const [mobileSort, setMobileSort] = useState(mobileTableSort[0].title);
  const sortItem = mobileTableSort.find((item) => item.title === mobileSort);
  const mobileSortList = mobileTableSort.map((item) => item.title);
  const [title, setTitle] = useState('');
  const [totalRowData, setTotalRowData] = useState<IInvestorTotalRow | null>(null);
  const [currentRow, setCurrentRow] = useState<ILegalEntity | null>(null);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'delete-entity'
  });

  useEffect(() => {
    if (!isMobile) return;
    investorRefetch({
      data: {
        id: params?.investorId,
        includeRecommendation: switcher,
        legalEntitiesList: {
          sortBy: sortItem?.key,
          sortDirection: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
          limit: Number(limit),
          offset: Number(offset)
        }
      }
    });
  }, [mobileSort]);

  const {
    data: investorData,
    refetch: investorRefetch,
    loading: investorLoading,
    error: investorError,
    networkStatus: investorNetworkStatus
  } = useQuery<IInvestorQuery>(GET_INVESTOR_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !params?.investorId,
    variables: {
      data: {
        id: params?.investorId,
        includeRecommendation: switcher,
        legalEntitiesList: {
          sortBy: key,
          sortDirection: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
          limit: Number(limit),
          offset: Number(offset)
        }
      }
    },
    onCompleted: (data) => {
      const { totalValue, totalCapacity, economicExposure, name, legalEntitiesPaginatedList } = data.getInvestor;

      const ignoreAllocationTargets = legalEntitiesPaginatedList.data.some((entity) => entity.ignoreAllocationTargets);

      setTableRows(legalEntitiesPaginatedList.data);
      setTotalRowData({ totalValue, totalCapacity, economicExposure, ignoreAllocationTargets });
      setTitle(name);
      makeScroll(position);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  useEffect(() => {
    if (!investorError && !investorLoading && investorData) {
      const { legalEntities } = investorData.getInvestor;

      const ignoreAllocationTargetsAllEntities = legalEntities.filter((entity) => !entity.ignoreAllocationTargets).length;

      setShowSpecificColumn(!!ignoreAllocationTargetsAllEntities);

      if (!ignoreAllocationTargetsAllEntities) {
        const filteredTableColumns = columns.filter(
          (column) =>
            column.key !== 'totalEntityValue' && column.key !== 'privatePercentage' && column.key !== 'overCommitRatio' && column.key !== 'capacity'
        );

        setTableColumns(filteredTableColumns);
        return;
      }
      setTableColumns(columns);
    }
  }, [investorData, investorLoading, investorError]);

  const toggleNotes = () => {
    setIsNotesOpen(!isNotesOpen);
    if (isMobile) return;

    const html = document.querySelector('html');
    if (html) {
      html.style.overflowY = isNotesOpen ? 'visible' : 'hidden';
    }
  };

  const openEntityDetails = (id: string) => {
    saveScrollPostion();
    navigate(`${id}?backToTitle=Investor`);
  };

  const openNotesOnEntityDetails = (id: string) => {
    saveScrollPostion();
    navigate(`${id}?backToTitle=Investor&openNotes=true`);
  };

  const openEditEntityPage = (id: string) => {
    saveScrollPostion();
    navigate(
      `/investors/create-investor?investorId=${encodeURIComponent(params?.investorId)}&entityId=${encodeURIComponent(id)}&backToTitle=Investor`
    );
  };

  const openEditInvestorPage = () => {
    saveScrollPostion();
    navigate(`edit?backToTitle=Investor`);
  };

  const toggleSwitcher = () => {
    setSearchParams(
      (prev) => {
        prev.set('switcher', Boolean(!switcher).toString());
        return prev;
      },
      { replace: true }
    );
    saveScrollPostion();
  };

  const onMobileRowClick = (data: ILegalEntity) => {
    setModalData(data);
    setIsOpen(true);
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const openModalWindow = (type: string, row?: ILegalEntity) => {
    setModalWindow({ type, isOpen: true });
    if (row) {
      setCurrentRow(row);
    }
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
    setCurrentRow(null);
  };

  if (investorNetworkStatus === NetworkStatus.loading) {
    return <CustomLoader size={60} />;
  }

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'delete-entity' && (
        <DeleteEntity
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentRow={currentRow}
          refetch={investorRefetch}
          total={investorData?.getInvestor?.legalEntitiesPaginatedList?.total ?? 0}
        />
      )}
      {isModalOpen && modalData && (
        <MobileModal onClose={() => setIsOpen(false)} title={modalData.entityName} onClick={() => openEntityDetails(modalData.id)}>
          <MobileInfoList onRedirect={() => openEntityDetails(modalData.id)} data={modalData} />
        </MobileModal>
      )}
      {isMobile && isNotesOpen && (
        <MobileModal onClose={toggleNotes} title="Notes" onClick={() => {}}>
          <MobileNotes data={investorData?.getInvestor.notes} openNotesOnEntityDetails={openNotesOnEntityDetails} />
        </MobileModal>
      )}
      <MainWrap>
        <Header
          specialControl={<SpecialControlHeader switcher={switcher} setSwitcher={toggleSwitcher} title="Include Pending Commitments" />}
          modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />}
        />
        <PageTitle title={title} onEditClick={openEditInvestorPage} />
        <Notes
          data={investorData?.getInvestor.notes}
          onClick={toggleNotes}
          isOpen={isNotesOpen}
          openNotesOnEntityDetails={openNotesOnEntityDetails}
        />
        <Details
          data={investorData?.getInvestor}
          isDetailsOpen={isDetailsOpen}
          setIsDetailsOpen={() => setIsDetailsOpen(!isDetailsOpen)}
          onNotesClick={() => null}
          setIsNotesOpen={toggleNotes}
        />
      </MainWrap>
      <MainWrap>
        <PaddingWrap isMobile={isMobile}>
          {isMobile && (
            <SortSelectWrapper>
              <FilterSelect
                isMobile
                label={'Sort By'}
                paddingContainer={'0 20px'}
                width={'100%'}
                data={mobileSortList}
                selected={mobileSort}
                setSelected={setMobileSort}
              />
            </SortSelectWrapper>
          )}
          <CustomRefetch isMobile={isMobile} isTablet={isTablet}>
            <Results>{investorData?.getInvestor?.legalEntitiesPaginatedList?.total ?? 0} Results</Results>
            <RefreshButton onClick={() => investorRefetch()}>
              <RefreshIcon fill={theme.action.primary} />
            </RefreshButton>
          </CustomRefetch>
          <Table
            tableRows={tableRows}
            sort={{ key, asc }}
            setSort={onChangeSort}
            refetch={() => null}
            loading={investorNetworkStatus === NetworkStatus.setVariables}
            openEntityDetails={openEntityDetails}
            openEditEntityPage={openEditEntityPage}
            onMobileRowClick={onMobileRowClick}
            showSpecificColumn={showSpecificColumn}
            tableColumns={tableColumns}
            totalRowData={totalRowData}
            openModalWindow={openModalWindow}
          />
          <PaginationWrap>
            <TablePagination
              savePagination={onChangePaginationData}
              paginationValues={{
                limit: Number(limit),
                offset: Number(offset),
                total: investorData?.getInvestor?.legalEntitiesPaginatedList?.total ?? 0
              }}
              refetch={() => null as any}
            />
          </PaginationWrap>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '20px 16px 0 16px' : '40px 16px 0 16px')};
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const CustomRefetch = styled(TopWrapper)<{
  isMobile: boolean;
  isTablet: boolean;
}>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile ? '120px' : isTablet ? '50px' : '0')};
  padding: 10px;
  background-color: ${({ theme }) => theme.layer.base};
  z-index: 10;
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CustomLoader = styled(Loader)`
  height: 100%;
  align-items: center;
`;

const SortSelectWrapper = styled.div`
  position: sticky;
  top: 50px;
  z-index: 11;
  background-color: ${({ theme }) => theme.layer.base};
  margin-top: 0;
  padding: 10px 0;
`;
