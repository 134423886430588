import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import Input from '@/components/fat-basicComponents/input';
import FooterModal from '@/components/fat-settings-page/importData/footer';
import { ProgressBar } from '@/components/fat-settings-page/importData/progressBar';
import { ImportWrapper } from '@/components/fat-settings-page/importData/styles';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { ICreateAutoPlanForm } from '..';

interface ForecastOptionsProps {
  autoPlanWizardSteps: { name: string; status: string }[];
  control: Control<ICreateAutoPlanForm, any>;
  trigger: UseFormTrigger<ICreateAutoPlanForm>;
  startOver: () => void;
  nextStep: () => void;
}

export const ForecastOptions = ({ autoPlanWizardSteps, control, trigger, startOver, nextStep }: ForecastOptionsProps) => {
  const theme = useTheme();

  const moveToTheNextStep = async () => {
    const isGrowthRateValid = await trigger('growthRate');
    if (!isGrowthRateValid) return;

    nextStep();
  };

  const handleCursorPosition = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const cursorPosition = input.selectionStart;
    const valueLength = input.value.length;

    if (cursorPosition !== null && cursorPosition >= valueLength - 1) {
      input.setSelectionRange(valueLength - 1, valueLength - 1);
    }
  };

  const validatePercentage = (value: string) => {
    const numericValue = parseFloat(value.replace('%', ''));
    if (isNaN(numericValue)) {
      return 'Invalid number';
    }
    if (numericValue < 0) {
      return 'Value must be at least 0';
    }
    if (numericValue > 100) {
      return 'Value must be at most 100';
    }
    return true;
  };

  return (
    <>
      <StepWrapper>
        <ProgressBar importSteps={autoPlanWizardSteps} />
        <StepDetailsWrapper>
          <StepTitle>Forecast Options</StepTitle>
          <StepDescription>
            When the investor’s portfolio is growing more quickly, larger commitments are required to meet the investor’s allocation targets. Control
            those assumptions here.
          </StepDescription>
        </StepDetailsWrapper>
        <StepBodyWrapper>
          <FieldWrapper>
            <Controller
              name="growthRate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Growth Rate ex. Drawdowns is required'
                },
                validate: validatePercentage
              }}
              render={({ field: { value, onChange, onBlur, ref }, fieldState: { error, invalid } }) => (
                <Input
                  label="Growth Rate ex. Drawdowns"
                  value={value}
                  onChange={(e) => {
                    handleCursorPosition(e);
                    let inputValue = e.target.value.replace('%', '');
                    onChange(inputValue + '%');
                  }}
                  onClick={handleCursorPosition}
                  onKeyUp={handleCursorPosition}
                  onBlur={onBlur}
                  errorText={error?.message}
                  isRequiredField
                />
              )}
            />
            <Banner
              title=""
              description="Provide a blended growth rate that applies to the investor’s public portfolio and any evergreen private capital funds."
              icon={<RejectedBannerIcon fill={theme.header.base} />}
              bgColor={theme.layer[1]}
              color={theme.font.base}
            />
          </FieldWrapper>
        </StepBodyWrapper>
      </StepWrapper>
      <FooterModal startOver={startOver} confirmButton={{ name: 'Continue', onClick: moveToTheNextStep, disabled: false }} />
    </>
  );
};

const StepWrapper = styled(ImportWrapper)`
  margin-bottom: 150px;
`;

const StepDetailsWrapper = styled.div``;

const StepTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 22.4px;
  text-align: center;
  margin-top: 10px;
  color: ${({ theme }) => theme.font.base};
`;

const StepDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  padding: 10px;
  color: ${({ theme }) => theme.font.weak};
`;

const StepBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;
